import { Delete } from '@mui/icons-material'; 
import React, { useState, useEffect } from 'react';
import { Button, MenuItem, Menu, Typography, Checkbox, ListItemText, IconButton } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import axios from 'axios';
import { Layout } from '../../components';
import { toast } from 'react-toastify';
import AppLoader from '../../components/Loader/AppLoader';
import { _BASE_URL, BASE_URL } from '../../Api/constants';
import { useSelector } from 'react-redux';
import { Image } from 'antd';
import Modal from '../../components/Modal';
import DeleteConfirmationModel from '../../components/DeleteConfirmationModel';

const TopStores = ({ services, loading, setLoading }) => {
    // const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [checkedServices, setCheckedServices] = useState(new Set()); // For tracking selected checkboxes
    const [draggingItem, setDraggingItem] = useState();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deletedId, setDeletedId] = useState('');


    const words = useSelector((state) => state.authReducer.words);



    const changeOrder = async (targetItem) => {
        const items = selectedServices;
        if (!draggingItem) return;

        const currentIndex = items.indexOf(draggingItem);
        const targetIndex = items.indexOf(targetItem);



        if (currentIndex !== -1 && targetIndex !== -1) {

            const body = {
                ranking_item_id: draggingItem.id,
                target_item_id: targetItem.id,
            };

            try {
                setLoading(true);
                await axios.post(BASE_URL + '/api/admin/updateStoreRank', body);
                // setSelectedServices(servicesToSave);
                setLoading(false);
                getTopStores()
                toast.success('Rank saved successfully.');
            } catch (error) {
                setLoading(false)
                console.log('Error saving services:', error);
                toast.error('Error saving services:');
            }

        }
        else {
            console.log('upper3');
            alert('err is here')
        }
    }



    const getTopStores = async () => {
        try {
            setLoading(true);
            const response = await axios.get(BASE_URL + '/api/admin/getTopStores');
            setSelectedServices(response.data?.data);

            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }


    const handleSave = async () => {
        const selectedArray = Array.from(checkedServices);
        const servicesToSave = selectedArray.map(serviceId => ({
            store_id: serviceId,
        }));
        // /

        try {
            setLoading(true);
            await axios.post(BASE_URL + '/api/admin/addTopStore', { stores: servicesToSave });
            getTopStores();
            setLoading(false);
            toast.success('Top store saved successfully.');
        } catch (error) {
            setLoading(false)
            console.log('Error saving services:', error);
            toast.error('Error saving services:');
        }

        handleClose();
    };

    const doDelete = async () => {
        try {
            setLoading(true);
            await axios.delete(BASE_URL + `/api/admin/deleteTopStore/${deletedId}`);
            setLoading(false);
            getTopStores();
            toast.success('Store deleted successfully.');
        } catch (error) {
            setLoading(false);
            console.log('Error deleting store:', error);
            toast.error('Error deleting store:');
        }
    };




    useEffect(() => {
        getTopStores();
    }, [])






    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setDropdownOpen(true);
    };

    const handleClose = () => {
        setDropdownOpen(false);
        setAnchorEl(null);
    };




    const handleCheckboxChange = (serviceId) => {
        setCheckedServices((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(serviceId)) {
                newSet.delete(serviceId);
            } else {
                newSet.add(serviceId);
            }
            return newSet;
        });
    };

    return (
        <>


            <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                    {/* <Typography variant="h4" className="font-semibold">Top Services</Typography> */}
                    <h1 className="text-[#18120F] text-xl min-w-fit font-semibold">
                        {words["Top stores"]}

                    </h1>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                            style={{ backgroundColor: '#D0934D' }}
                            endIcon={<ArrowDropDown />}
                        >
                            {words["Add"]}
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={dropdownOpen}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: 400,
                                    width: '30ch',
                                },
                            }}
                        >
                            {services.map((service) => (
                                <MenuItem
                                    key={service.id}>
                                    <Checkbox
                                        disabled={service.is_added}
                                        checked={checkedServices.has(service.store_id) || service.is_added}
                                        onChange={() => handleCheckboxChange(service.store_id)}
                                        color="primary"
                                    />
                                    <ListItemText primary={`${service.store_name}`} />
                                </MenuItem>
                            ))}
                            <MenuItem onClick={handleSave}>{words["Save"]}</MenuItem>
                        </Menu>
                    </div>
                </div>

                <div

                    className="flex space-x-4 overflow-x-auto">
                    {selectedServices?.map((service) => (
                        <div
                            onDragStart={(e, item) => {
                                console.log('starting');
                                setDraggingItem(service);
                            }}
                            onDragEnd={() => { }}
                            onDragOver={(e) => {
                                e.preventDefault();
                            }}
                            onDrop={(e) => {
                                changeOrder(service)
                            }}
                            draggable={true}

                            key={service.store_id}
                            // className="flex-shrink-0 w-64 p-4 bg-white rounded-lg shadow-md"
                            className="relative flex-shrink-0 w-64 p-4 bg-white rounded-lg shadow-md"

                        >
                            <Image
                                src={_BASE_URL + service.store?.logo}
                                // alt={service.service?.title}
                                className="w-full h-32 object-cover rounded-md mb-2"
                            />
                            <Typography variant="h6" className="font-medium">{service.store?.store_name}</Typography>
                            {/* <Typography variant="body2" color="textSecondary">{service.product?.price + " " + words["OMR"]} </Typography> */}

                            <IconButton
                                onClick={() => {
                                    setDeletedId(service.store_id)
                                    setOpenDeleteModal(true);
                                }}
                                className="absolute bottom-2 right-2"
                                style={{ position: 'absolute' }}
                                color="error"
                            >
                                <Delete />
                            </IconButton>


                        </div>
                    ))}
                </div>
            </div>


            <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
                <DeleteConfirmationModel
                    openModal={openDeleteModal}
                    onSubmit={() => doDelete()}
                    setOpenModal={setOpenDeleteModal}
                />
            </Modal>



        </>
    );
};

export default TopStores;
