import React, { useEffect, useState } from "react";
import Select from "react-select";
import { CrossIcon, ImageIcon } from "../assets/Icons";
import { useCreateBannerMutation } from "../store/services/bannerService";
import { useSelector } from "react-redux";

const BannerModel = ({ openModal, setOpenModal }) => {

  const words = useSelector((state) => state.authReducer.words);

  const [img, setImg] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [submitData, response] = useCreateBannerMutation();
  // console.log("Banner Store Resp...", response);
  const options = [
    { value: "store", label: words["Store"] },
    { value: "user", label: words["User"] },
  ];
  const submitFormData = () => {
    const data = new FormData();
    data.append("type", selectedOption.label);
    data.append("status", "Active");
    data.append("image", img);
    submitData(data);
  };
  useEffect(() => {
    if (response?.isSuccess) {
      setOpenModal(false);
      window.location.reload();
    }
  }, [response]);
  return (
    <div className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
      <div className="flex items-center justify-between gap-3">
        <h1 className="text-black text-xl font-medium">{words["Add Banner"]}</h1>
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">{words['Title']}</label>
          <Select
            placeholder={words['Select']}
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
            className="w-full"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">{words['image']}</label>
          <div className="flex items-center">
            <div className="field flex flex-col">
              <div
                className="img-box flex items-center justify-center h-40 w-64 bg-[#EFEFEF] border border-[#808080] rounded-lg relative cursor-pointer"
                onClick={() => document.getElementById("upload_img").click()}
              >
                {img ? (
                  <img
                    alt="img"
                    src={URL.createObjectURL(img)}
                    className="h-full w-full overflow-hidden object-contain"
                  />
                ) : (
                  <div className="flex items-center justify-center h-full w-full">
                    <ImageIcon />
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  title=""
                  name="image"
                  id="upload_img"
                  className="hidden cleanbtn"
                  onChange={(e) => {
                    setImg(e.target.files[0]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <button
            className="button rounded-full !py-2 !px-10"
            onClick={(e) => submitFormData()}
          >
            {words['save']}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BannerModel;
