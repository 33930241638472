import React, { useEffect, useState } from "react";
import { CrossIcon } from "../assets/Icons";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useSendNotificationMutation } from "../store/services/notificationService";
import AppLoader from "./Loader/AppLoader";
import { useSelector } from "react-redux";

const NotificationModel = ({ openModal, setOpenModal, users, type = "users" }) => {


  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [sendNotification, response] = useSendNotificationMutation();



  const handleUserSelection = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handlePostNotification = () => {

    if (!title) return toast.error('Please enter title');
    if (!description) return toast.error('Please enter description');
    if (!selectedUsers.length) return toast.error('Please select user');

    const body = {
      title,
      description,
      user_id: selectedUsers,
      is_store: type == "stores" ? 1 : 0
    }
    sendNotification(body);

    // // Perform your logic to post notification to selected users
    // console.log("Selected Users:", selectedUsers);
    // console.log("Title:", title);
    // console.log("Description:", description);


  };

  const handleSearchChange = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  const filteredUsers = type == 'stores' ?
    users.filter(user =>
      user.store_name?.toLowerCase().includes(searchTerm) || user.store_number?.toLowerCase().includes(searchTerm)
    ) :
    users.filter(user =>
      user.name?.toLowerCase().includes(searchTerm) || user.phone_number?.toLowerCase().includes(searchTerm)
    );


  useEffect(() => {
    console.log('is Succes', response)
    if (response.isSuccess) {
      toast.success('Notification send successfully');
      setOpenModal(false);
      setTitle("");
      setDescription("");
      setSelectedUsers([]);
    }
    if (response.isError) {
      toast.error('Error while sending notification. Please try again later');
    }
  }, [response.isSuccess, response.isError])



  return (
    <div
      dir={lang == 'ar' ? "rtl" : "ltr"}
      className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
      {
        response.isLoading && <AppLoader />
      }
      <div className="flex items-center justify-between gap-3 mb-5">
        <h1 className="text-black text-lg font-medium">{words["Send Notification"]}</h1>
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
      </div>

      <div

        className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">{words["Title"]}</label>
          <input
            type="text"
            // placeholder="Enter Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">{words["Description"]}</label>
          <textarea
            // placeholder="Enter the Description here"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="min-h-[110px] max-h-[200px] border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div>

        <div
          className="flex flex-col gap-2">
          <label
            className="text-black text-sm font-normal">{words["Select"]} {type == "stores" ? words["store"] : words["users"]}</label>
          {/* {users.map((user) => (
            <div key={user.id} className="flex items-center gap-2">
              <input
                type="checkbox"
                id={`user_${user.id}`}
                checked={selectedUsers.includes(user.id)}
                onChange={() => handleUserSelection(user.id)}
                className="border border-[#D9D9D9] rounded-md focus:ring-themeColor"
              />
              <label htmlFor={`user_${user.id}`} className="text-sm">
                {user.name} - {user.number}
              </label>
            </div>
          ))} */}


          {/* <label className="text-black text-sm font-normal">Select User</label> */}
          <input
            type="text"
            placeholder={words["search"]}
            value={searchTerm}
            onChange={(e) => handleSearchChange(e.target.value)}
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />


          {/* <TextField
            fullWidth
            label="Search Users"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            margin="normal"
          /> */}
          <Grid container spacing={2} marginTop={2}>
            {filteredUsers.map((user) => (
              <Grid item xs={12} sm={6} key={user.id}>

                {/* <div key={user.id} className="flex items-center gap-2"> */}
                <input
                  type="checkbox"
                  id={`user_${user.id}`}
                  checked={selectedUsers.includes(type == 'stores' ? user.store_id : user.user_id)}
                  onChange={() => handleUserSelection(type == 'stores' ? user.store_id : user.user_id)}
                  className="border border-[#D9D9D9] rounded-md focus:ring-themeColor"
                />
                {
                  type == 'stores' ?
                    <label htmlFor={`user_${user.id}`} className="text-sm" style={{ marginLeft: 10 }} >
                      {user.store_name} - {user.store_number}
                    </label> :
                    <label htmlFor={`user_${user.id}`} className="text-sm" style={{ marginLeft: 10 }} >
                      {user.name} - {user.phone_number}
                    </label>
                }

                {/* </div>   */}

                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleUserSelection(user.id)}
                    />
                  }
                  label={`${user.name} - ${user.number}`}
                /> */}
              </Grid>
            ))}
          </Grid>


        </div>

        <div className="flex items-center justify-center">
          <button className="button rounded-full !py-2 !px-10" onClick={handlePostNotification}>
            {words["Post"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationModel;




// import React, { useState } from "react";
// import Select from "react-select";
// import { CrossIcon, ImageIcon } from "../assets/Icons";

// const NotificationModel = ({ openModal, setOpenModal }) => {
//   const [img, setImg] = useState();
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [selectedNotification, setSelectedNotification] = useState(null);

//   const options = [
//     { value: "1", label: "User 1" },
//     { value: "2", label: "User 2" },
//     { value: "3", label: "User 3" },
//   ];

//   return (
//     <div className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
//       <div className="flex items-center justify-between gap-3">
//         <h1 className="text-black text-xl font-medium">Add Notification</h1>
//         <div
//           className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
//           onClick={() => setOpenModal(false)}
//         >
//           <CrossIcon />
//         </div>
//       </div>
//       <div className="flex items-center">
//         <div className="field flex flex-col my-5">
//           <div
//             className="img-box flex items-center justify-center h-24 w-24 bg-[#EFEFEF] border border-[#808080] rounded-lg relative cursor-pointer"
//             onClick={() => document.getElementById("upload_img").click()}
//           >
//             {img ? (
//               <img
//                 alt="img"
//                 src={URL.createObjectURL(img)}
//                 className="h-full w-full overflow-hidden object-contain"
//               />
//             ) : (
//               <div className="flex items-center justify-center h-full w-full">
//                 <ImageIcon />
//               </div>
//             )}
//             <input
//               type="file"
//               accept="image/*"
//               title=""
//               name="image"
//               id="upload_img"
//               className="hidden cleanbtn"
//               onChange={(e) => {
//                 setImg(e.target.files[0]);
//               }}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="flex flex-col gap-5">
//         <div className="flex flex-col gap-2">
//           <label className="text-black text-sm font-normal">Send To</label>
//           <Select
//             defaultValue={selectedOption}
//             onChange={setSelectedOption}
//             options={options}
//             className="w-full"
//           />
//         </div>
//         <div className="flex flex-col gap-2">
//           <label className="text-black text-sm font-normal">
//             Type of Notification
//           </label>
//           <Select
//             defaultValue={selectedNotification}
//             onChange={setSelectedNotification}
//             options={options}
//             className="w-full"
//           />
//         </div>
//         <div className="flex flex-col gap-2">
//           <label className="text-black text-sm font-normal">Title</label>
//           <input
//             type="text"
//             placeholder="Enter Title"
//             className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
//           />
//         </div>
//         <div className="flex flex-col gap-2">
//           <label className="text-black text-sm font-normal">Message</label>
//           <textarea
//             type="text"
//             placeholder="Enter the Message here"
//             className="min-h-[110px] max-h-[200px] border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
//           />
//         </div>

//         <div className="flex items-center justify-center">
//           <button className="button rounded-full !py-2 !px-10">Save</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NotificationModel;
