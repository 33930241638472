import React from "react";

function BooksIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M8.062 2v3M16.123 2v3M21.162 8.5V17c0 3-1.512 5-5.039 5H8.062c-3.527 0-5.039-2-5.039-5V8.5c0-3 1.512-5 5.039-5h8.061c3.527 0 5.039 2 5.039 5zM8.062 11h8.061M8.062 16h4.03"
      ></path>
    </svg>
  );
}

export default BooksIcon;
