// export const ar = {
//     'home': 'بيت',
//     'dashboard': 'لوحة القيادة',
//     'stores': 'مخازن',
//     'bookings': 'الحجوزات',
//     'users': 'المستخدمين',
//     'finance': 'تمويل',
//     'banners': 'لافتات',
//     'featured sections': 'أقسام مميزة',
//     'notifications': 'إشعارات',
//     'settings': 'إعدادات',
//     'logout': 'تسجيل خروج',
//     'income': 'دخل',

//     'no': 'لا',
//     'store name': 'اسم المتجر',
//     'phone': 'هاتف',
//     'email': 'بريد إلكتروني',
//     'id store': 'متجر الهوية',
//     'location': 'موقع',
//     'publishing': 'نشر',
//     'status': 'حالة',
//     'action': 'فعل',

//     'type': 'يكتب',
//     'phone': 'هاتف',
//     'id': 'بطاقة تعريف',
//     'image': 'صورة',
//     'name': 'اسم',
//     'search': 'يبحث',
//     'add store': 'إضافة متجر',
//     'publishing': 'نشر',

//     'general': 'عام',
//     'store information': 'معلومات المتجر',
//     'service list': 'قائمة الخدمة',
//     'product list': 'قائمة المنتجات',
//     'store details': 'تفاصيل المتجر',
//     'income': 'دخل',
//     'products': 'منتجات',
//     'orders': 'طلبات',
//     'services': 'خدمات',
//     'evaluation': 'تقييم',
//     'OMR': '﷼',
//     'Store Name': 'اسم المتجر',
//     'Visiting Charge': 'رسوم الزيارة',
//     'Store Name': 'اسم المتجر',
//     'Address': 'عنوان',
//     'City': 'مدينة',
//     'At Home': 'في البيت',
//     'At Store': 'في متجر',
//     'About Company': 'عن الشركة',
//     'Long Description': 'وصف طويل',
//     'Name': 'اسم',
//     'Email': 'بريد إلكتروني',
//     'Phone': 'هاتف',
//     'Commission': 'عمولة',
//     'Sunday': 'الأحد',
//     'Monday': 'الاثنين',
//     'Tuesday': 'يوم الثلاثاء',
//     'Wednesday': 'الأربعاء',
//     'Thursday': 'يوم الخميس',
//     'Friday': 'جمعة',
//     'Saturday': 'السبت',
//     'Bank Name': 'اسم البنك',
//     'Account Number': 'رقم حساب',
//     'Account Name': 'إسم الحساب',

//     'Logo': 'شعار',
//     'Banner': 'راية',
//     'Company Details': 'تفاصيل الشركة',
//     'Bank Details': 'التفاصيل المصرفية',
//     'Personal Information': 'معلومات شخصية',
//     'Edit Store': 'تحرير المتجر',
//     'Active': 'نشيط',

//     'Visiting Charges': 'رسوم الزيارة',
//     'At Store': 'في متجر',
//     'At Home': 'في البيت',
//     'About this store': 'حول هذا المتجر',
//     'Working Days': 'أيام العمل',
//     'Images': 'الصور',
//     'Photos': 'الصور',
//     'Location Information': 'معلومات الموقع',
//     'Current Location': 'الموقع الحالي',
//     'CR Certification': 'شهادة CR',
//     'ID Card': 'بطاقة التعريف',
//     "To": "ل",
//     "off": "عن",
//     "on": 'على',
//     "Map": "خريطة",
//     'Phone Number': 'رقم التليفون',
//     'save': 'يحفظ',
//     'Create store': 'إنشاء متجر',

//     'Your email' : 'بريدك الالكتروني',
//     'Type your password' : 'اكتب كلمة المرور الخاصة بك',
//     'Password' : 'كلمة المرور',
//     'Sign in' : 'تسجيل الدخول',
//     'Loading' : 'تحميل',
//     'Created at' : 'أنشئت في',
//     'Edit' : 'يحرر',
//     'Add' : 'يضيف',
//     'Delete' : 'يمسح',
//     'Edit user': 'تحرير العضو',
//     'Add user' : 'إضافة مستخدم',













// }
export const ar = {
    'home': 'بيت',
    'users': 'مستخدمين',
    'stores': 'متاجر',
    'bookings': 'طلبات',
    'finance': 'المعاملات',
    'banners': 'الاعلانات',
    'featured': 'الاقسام',
    'notifications': 'الاشعارات',
    'settings': 'الاعدادات',
    'logout': 'خروج',
    'income': 'دخل',

    'no': 'لا',
    'store name': 'اسم المتجر',
    'phone': 'رقم الهاتف',
    'email': 'البريد الالكتروني',
    'id store': 'رمز المتجر',
    'location': 'الموقع',
    'publishing': 'منشور',
    'status': 'الحالة',
    'action': 'اعداد',
    'type': 'نوع',
    'phone': 'رقم الهاتف',
    'id': 'رمز',
    'image': 'صورة',
    'name': 'اسم',
    'search': 'بحث',
    'add store': 'اضافة متجر',
    'publishing': 'منشور',
    'general': 'عام',
    'store information': 'معلومات المتجر',
    'service list': 'قائمة الخدمات',
    'product list': 'قائمة المنتجات',
    'store details': 'تفاصيل المتجر',

    'income': 'دخل',
    'products': 'منتجات',
    'orders': 'طلبات',
    'services': 'خدمات',
    'evaluation': 'تقييمات',
    "OMR": 'ر.ع',

    'Store Name': 'اسم المتجر',
    'Visiting Charge': 'تكلفة الزيارة',
    'Store Name': 'اسم المتجر',
    'Address': 'العنوات',
    'City': 'المدينة',
    'At Home': 'في المنزل',
    'At Store': 'في المتجر',
    'About Company': 'عن المتجر',
    'Long Description': 'وصف طويل',
    'Name': 'الاسم',
    'Email': 'البريد الالكتروني',
    'Phone': 'رقم الهاتف',
    'Commission': 'العمولة',
    'Sunday': 'الاحد',
    'Monday': 'الاثنين',
    'Tuesday': 'الثلاثاء',
    'Wednesday': 'الاربعاء',
    'Thursday': 'الخميس',
    'Friday': 'الجمعة',
    'Saturday': 'السبت',
    'Bank Name': 'اسم البنك',
    'Account Number': 'رقم الحساب',
    'Account Name': 'اسم الحساب',

    'Logo': 'شعار',
    'Banner': 'لوحة',
    'Company Details': 'تفاصيل المتجر',
    'Bank Details': 'بيانات البنك',
    'Personal Information': 'معلومات شخصية',
    'Edit Store': 'تعديل المتجر',
    'Active': 'فعال',
    'featured sections': 'الاقسام',

    'Visiting Charges': 'تكلفة الزيارة',
    'At Store': 'في المتجر',
    'At Home': 'في المنزل',
    'About this store': 'عن هذا المتجر',
    'Working Days': 'ايام العمل',
    'Images': 'الصور',
    'Photos': 'الصور',
    'Location Information': 'معلومات الموقع',
    'Current Location': 'الموقع الحالي',
    'CR Certification': 'السجل التجاري',
    'ID Card': 'البطاقة الشخصية',
    "To": "الى",
    "on": 'مفتوح',
    "off": 'مغلق',
    "Map": "خريطة",
    'Phone Number': 'رقم الهاتف',
    "save": "حفظ",
    'Create store': 'انشاء متجر',

    'Your email': 'بريدك الالكتروني',
    'Type your password': 'الرمز السري',
    'Password': 'الرمز السري',
    'Sign in': 'تسجيل',
    'Loading': 'تحميل',
    'Created at': 'تم الانشاء في',
    'Edit': 'تعديل',
    "Add": "اضافة",
    'Delete': 'حذف',
    'Edit user': 'تعديل المستخدم',
    'Add user': 'اضافة مستخدم',

    'Setting': 'الاعدادات',
    'Terms & Privacy Settings': 'شروط الاستخدام',


    'Customer Terms and Conditions': 'شروط الاستخدام',
    'Customer privacy policy': 'بيانات الخصوصية',
    'Store Terms and Conditions': 'شروط الاستخدام للمتاجر',
    'Store Privacy Policy': 'بيانات الخصوصية للمتاجر',

    'Notification': 'الاشعارات',
    'Title': 'العنوان',
    'Description': 'الوصف',
    'Date': 'التاريخ',

    'Featured Section': 'الاقسام',
    'Rank': 'الترتيب',
    'Add Featured Section': 'اضافة قسم',
    'Section Types': 'نوع القسم',
    'Add Featured': 'اضافة قسم',
    'Add Banner': 'اضافة اعلان',
    'Select': 'اختيار',
    'Store': 'متجر',
    'User': 'متسخدم',
    'Edit Banner': 'تعديل اعلان',

    'Order Id': 'رمز الطلب',
    'User name': 'اسم المستخدم',
    'Order amount': 'قيمة الطلب',
    'Admin commision': 'عمولة الشركة',
    'Store amount': 'مبلغ المتجر',
    'Transaction date': 'تاريخ التحويل',
    'Is Paid to store': 'هل دفعت للمتجر',
    'Paid': 'مدفوع',
    'Mark as paid': 'اقرار الدفع',

    'Booking Detail': 'تفاصيل الطلب',
    'Date of order': 'تاريخ الطلب',
    'Payment status': 'حالة الدفع',
    'Price': 'المبلغ',
    'Quantity': 'العدد',
    'Customer Details': 'بيانات العميل',
    'Contact Number': 'رقم التواصل',
    'Co %': 'نسبة %',
    'Total': 'الاجمالي',
    'Store Info': 'معلومات المتجر',
    'Company Name': 'اسم المتجر',
    'User phone': 'رقم العميل',
    'Paymnet status': 'حالة الدفع',
    'View': 'اطلاع',
    'Timing Details': 'تفاصيل التوقيت',
    'Are you sure you want to delete this store?': 'هل انت متاكد من حذف المتجر؟',
    'Yes': 'نعم',
    'No': 'لا',
    'Cancel': 'الغاء',
    'Service Name': 'اسم الخدمة',
    'ID Store': 'رمز المتجر',
    'Detail': 'التفاصيل',
    'View Service': 'مشاهدة الخدمة',
    'Service Details': 'تفاصيل الخدمة',
    'Service Price': 'قيمة الخدمة',
    'Basic Details': 'تفاصيل اساسية',
    'Tamsir Type': 'نوع التمصيرة',
    'Tamsir Color': 'لون التمصيرة',
    'Tamsir Accessory': 'اكسسوارات التمصيرة',
    'Service description': 'وصف الخدمة',
    'Product Name': 'اسم المنتج',
    'Product Details': 'تفاصيل المنتج',
    'View Product': 'مشاهدة المنتج ',
    'store phone': 'تخزين الهاتف',
    'Send Notification': 'إرسال الإخطار',
    'Post': 'بريد',
    'Save': 'يحفظ',

    'Top services': 'أعلى الخدمات',
    'Top products': 'أعلى المنتجات',
    'top': 'قمة',
    'Top stores': 'أعلى المتاجر',
    "State": "ولاية",

    "Company Whatsapp": "شركة واتس اب",
    "Company X": "الشركة X",
    "Company Instagram": "انستغرام الشركة",





























}