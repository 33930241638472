import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAdminLoginMutation } from "../../store/services/authService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAdminToken } from "../../store/reducers/authReducer";
import { EyeIcon } from "../../assets/Icons";

const Login = () => {

  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const [login, response] = useAdminLoginMutation();

  const [showPassword, setShowPassword] = useState(false);
  const adminLoginFunction = (e) => {
    e.preventDefault();
    login(state);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (response.isSuccess) {
      toast.success(response?.data?.message || "");
      localStorage.setItem('admin', response.data?.user);
      localStorage.setItem("admin-token", response?.data?.token);
      dispatch(setAdminToken(response?.data?.token));
      navigate("/dashboard/home");
    }
    if (response.isError) {
      toast.error(response?.error?.data?.message || "");
    }
  }, [response]);

  return (
    <div className="bg-themeColor min-h-screen flex items-center">
      <div className="wrapWidth flex items-center">
        <div className="flex items-center md:flex-row flex-col gap-10 w-full py-14">
          <form
            className="flex flex-col gap-6 sm:w-[380px] w-full bg-white py-5 sm:px-10 px-5 rounded-xl"
            onSubmit={adminLoginFunction}
          >
            <div className="flex items-center justify-center py-12">
              <img src="./images/logo.svg" className="h-auto w-44" />
            </div>

            <div className="flex flex-col gap-2">
              <label
                htmlFor="email"
                className="block text-lg font-light leading-6 text-black"
              >
                {words["email"]}
              </label>
              <input
                type="email"
                name="email"
                className="input"
                onChange={handleInputs}
                value={state.email}
                required
                placeholder={words["Your email"]}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                htmlFor="password"
                className="block text-lg font-light leading-6 text-black"
              >
                {words["Password"]}
              </label>
              <div className="input flex items-center gap-1">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="w-full"
                  onChange={handleInputs}
                  value={state.password}
                  required
                  placeholder={words["Type your password"]}
                />
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={(e) => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <EyeIcon />
                </button>
              </div>
            </div>
            <div className="mt-12 mb-3">
              <input
                type="submit"
                value={response.isLoading ? words["Loading"] + "..." : words["Sign in"]}
                className="bg-themeColor w-full px-4 py-3 rounded-full text-white text-base font-medium cursor-pointer"
              />
            </div>
          </form>
          <div className="hidden flex-1 md:flex sm:flex items-center justify-center">
            <img src="/images/login-bg.webp" className="h-[580px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
