import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const bannerService = createApi({
  reducerPath: "banner",
  tagTypes: "banners",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createBanner: builder.mutation({
        query: (data) => {
          return {
            url: "/api/banners/store",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["banners"],
      }),
      updateBanner: builder.mutation({
        query: (data) => {
          return {
            url: "/api/banners/update/" + data.id,
            method: "POST",
            body: data.data,
          };
        },
        invalidatesTags: ["banners"],
      }),
      getBanners: builder.query({
        query: () => {
          return {
            url: "/api/banners",
            method: "GET",
          };
        },
        providesTags: ["banners"],
      }),
      deleteBanner: builder.mutation({
        query: (id) => {
          return {
            url: "/api/banners/delete/"+id,
            method: "GET",
            // body: id,
          };
        },
        invalidatesTags: ["banners"],
      }),
    };
  },
});
export const { useCreateBannerMutation, useUpdateBannerMutation, useGetBannersQuery, useDeleteBannerMutation } = bannerService;
export default bannerService;
