import Layout from "../../components/Layout";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
import { EditIcon, EditIcon2, GalleryIcon } from "../../assets/Icons";
import { useFetchSingleServiceQuery, useUpdateServiceMutation, useUpdateTamsirAccMutation, useUpdateTamsirColorMutation, useUpdateTamsirTypeMutation } from "../../store/services/storeService";
import { Input } from "../../components";

import { BASE_URL } from "../../Api/constants";
import { useEffect, useState } from "react";
import { Delete, PlusOne } from "@mui/icons-material";
import { useForceUpdate } from "../../utils/functions";
import { toast } from "react-toastify";
import AppLoader from "../../components/Loader/AppLoader";
const ServiceDetail = () => {


  const { id } = useParams();
  const forceUpdate = useForceUpdate();
  const { data: storeServiceData, isLoading: storeServiceLoading } =
    useFetchSingleServiceQuery(id);

  const [updateTamsirType, response,] = useUpdateTamsirTypeMutation();
  const [updateTamsirColor, responseTamsirColor] = useUpdateTamsirColorMutation();
  const [updateTamsirAcc, responseTamsirAcc] = useUpdateTamsirAccMutation();
  const [updateService, responseService] = useUpdateServiceMutation();


  const [tamsirTypes, setTamsirTypes] = useState([]);
  const [deletedTamsirTypes, setDeletedTamsirTypes] = useState([]);
  const [isEditingServiceType, setIsEditingServiceType] = useState(false);
  const [toBeAddedTamsirTypes, setToBeAddedTamsirTypes] = useState([]);

  const [tamsirColors, setTamsirColors] = useState([]);
  const [deletedTamsirColors, setDeletedTamsirColors] = useState([]);
  const [isEditingServiceColor, setIsEditingServiceColor] = useState(false);
  const [toBeAddedTamsirColors, setToBeAddedTamsirColors] = useState([]);

  const [tamsirAcc, setTamsirAcc] = useState([]);
  const [deletedTamsirAcc, setDeletedTamsirAcc] = useState([]);
  const [isEditingServiceAcc, setIsEditingServiceAcc] = useState(false);
  const [toBeAddedTamsirAcc, setToBeAddedTamsirAcc] = useState([]);

  const [isEditingService, setIsEditingService] = useState(false);

  const [serviceName, setServiceName] = useState(storeServiceData?.data?.title);
  const [servicePrice, setServicePrice] = useState(storeServiceData?.data?.price);
  const [serviceDesc, setServiceDesc] = useState(storeServiceData?.data?.detail)

  const serviceDetail = [
    {
      label: "Service Name",
      value: `${storeServiceData?.data ? storeServiceData?.data?.title : "-"}`,
    },
    {
      label: "Service Price",
      value: `${storeServiceData?.data ? storeServiceData?.data?.price : "-"}`,
    },
    {
      label: "Description",
      value: `${storeServiceData?.data ? storeServiceData?.data?.detail : "-"}`,
    },
    // {
    //   label: "Duration",
    //   value: "Muscat, Seeb",
    // },
    // {
    //   label: "At Home",
    //   value: "Yes",
    // },
    // {
    //   label: "At Store",
    //   value: "No",
    // },
  ];

  const deleteTamsirType = (type) => {
    let arr = [...tamsirTypes];
    let findIndex = arr.indexOf(type);
    if (findIndex !== -1) {
      arr.splice(findIndex, 1);
      if (type.tam_type_id) {
        let arr = [...deletedTamsirTypes];
        arr.push(type.tam_type_id);
        setDeletedTamsirTypes(arr);
      }
      setTamsirTypes(arr);
      forceUpdate();
    }
  }

  const doUpdateTamsirType = () => {
    const body = {
      deleted_ids: deletedTamsirTypes,
      new_types: tamsirTypes.filter((v) => !v.id),
      service_id: id.replace('ser-', '')
    }
    const data = new FormData();
    // formData.append('deleted_ids', deletedTamsirTypes);
    for (let key of deletedTamsirTypes) {
      data.append('deleted_ids[]', key)
    }
    let newTamsirTypes = tamsirTypes.filter((v) => !v.id);
    for (let key of newTamsirTypes) {
      data.append('new_types[]', key);
    }

    data.append('service_id', id.replace('ser-', ''));
    setIsEditingServiceType(false);

    console.log('data', data);
    updateTamsirType(data);
  }

  useEffect(() => {
    setTamsirTypes(storeServiceData?.data?.tamsir_types);
    setTamsirColors(storeServiceData?.data?.tamsir_colors);
    setTamsirAcc(storeServiceData?.data?.accessories);

    setServiceName(storeServiceData?.data?.title);
    setServicePrice(storeServiceData?.data?.price);
    setServiceDesc(storeServiceData?.data?.detail)


  }, [storeServiceData]);

  useEffect(() => {
    let count = 3 - tamsirTypes?.length;
    const array = Array.from({ length: count }, (v, k) => k);
    setToBeAddedTamsirTypes(array);
  }, [tamsirTypes])

  useEffect(() => {
    if (response.isSuccess) {
      setDeletedTamsirTypes([]);
      toast.success('Tamsir type updated successfully');
    }
    if (response.isError) {
      toast.error('Error while updating tamsir type. Please try again later');
    }
  }, [response]);



  const deleteTamsirColor = (type) => {
    let arr = [...tamsirColors];
    let findIndex = arr.indexOf(type);
    if (findIndex !== -1) {
      arr.splice(findIndex, 1);
      if (type.tam_color_id) {
        let arr = [...deletedTamsirColors];
        arr.push(type.tam_color_id);
        setDeletedTamsirColors(arr);
      }
      setTamsirColors(arr);
      forceUpdate();
    }
  }

  const doUpdateTamsirColor = () => {

    const data = new FormData();
    // formData.append('deleted_ids', deletedTamsirTypes);
    for (let key of deletedTamsirColors) {
      data.append('deleted_ids[]', key)
    }
    let newTamsirColors = tamsirColors.filter((v) => !v.id);
    for (let key of newTamsirColors) {
      data.append('new_colors[]', key);
    }

    data.append('service_id', id.replace('ser-', ''));
    setIsEditingServiceColor(false);
    updateTamsirColor(data);
  }

  useEffect(() => {
    let count = 3 - tamsirColors?.length;
    const array = Array.from({ length: count }, (v, k) => k);
    setToBeAddedTamsirColors(array);
  }, [tamsirColors]);

  useEffect(() => {
    if (responseTamsirColor.isSuccess) {
      setDeletedTamsirColors([]);
      toast.success('Tamsir color updated successfully');
    }
    if (responseTamsirColor.isError) {
      toast.error('Error while updating tamsir color. Please try again later');
    }
  }, [responseTamsirColor]);


  const deleteTamsirAcc = (type) => {
    let arr = [...tamsirAcc];
    let findIndex = arr.indexOf(type);
    if (findIndex !== -1) {
      arr.splice(findIndex, 1);
      if (type.tam_accessories_id) {
        let arr = [...deletedTamsirAcc];
        arr.push(type.tam_accessories_id);
        setDeletedTamsirAcc(arr);
      }
      setTamsirAcc(arr);
      forceUpdate();
    }
  }

  const doUpdateTamsirAcc = () => {

    const data = new FormData();
    // formData.append('deleted_ids', deletedTamsirTypes);
    for (let key of deletedTamsirAcc) {
      data.append('deleted_ids[]', key)
    }
    let newTamsirAcc = tamsirAcc.filter((v) => !v.id);
    for (let key of newTamsirAcc) {
      data.append('new_accessories[]', key);
    }

    data.append('service_id', id.replace('ser-', ''));
    setIsEditingServiceAcc(false);
    updateTamsirAcc(data);
  }

  useEffect(() => {
    let count = 3 - tamsirAcc?.length;
    const array = Array.from({ length: count }, (v, k) => k);
    setToBeAddedTamsirAcc(array);
  }, [tamsirAcc]);

  useEffect(() => {
    if (responseTamsirAcc.isSuccess) {
      setDeletedTamsirColors([]);
      toast.success('Tamsir accessory updated successfully');
    }
    if (responseTamsirAcc.isError) {
      toast.error('Error while updating tamsir accessory. Please try again later');
    }
  }, [responseTamsirAcc]);


  const doUpdateService = () => {

    const body = {
      title: serviceName,
      detail: serviceDesc,
      price: servicePrice,
      service_id: id
    }
    updateService(body);
  }

  useEffect(() => {
    console.log(responseService);
    if (responseService.isSuccess) {
      toast.success('Service updated successfully');
      setIsEditingService(false);
    }
    if (responseService.isError) {
      toast.error('Error while updating Service. Please try again later');
    }
  }, [responseService]);



  return (
    <>
      {
        response.isLoading || responseTamsirAcc.isLoading || responseTamsirColor.isLoading || responseService.isLoading &&
        <AppLoader />
      }

      <Layout pageTitle="View Service">
        <div className="w-full min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="w-full flex flex-col gap-4">
              <div className="w-full flex gap-4">
                <div className="w-full flex flex-col gap-8 grid-box px-5 py-5 rounded-[20px]">
                  <div className="w-full flex items-center">
                    <div className="text-[#18120F] text-xl font-bold flex-1">
                      Service Details
                    </div>
                    <div>
                      <button
                        onClick={() => setIsEditingService(true)}
                        className="h-7 w-7 text-themeColor">
                        <EditIcon2 />
                      </button>
                      {

                        isEditingService &&
                        <button
                          onClick={() => {
                            setIsEditingService(false);
                            // setTamsirTypes(storeServiceData?.data?.tamsir_types);
                          }}
                        >
                          <div className="text-[#18120F] font-semibold ml-4">Cancel</div>
                        </button>
                      }

                    </div>

                  </div>
                  <div className="w-full grid grid-cols-2 gap-5">
                    {!isEditingService && serviceDetail.map((item, index) => (
                      <div
                        key={index}
                        className="w-full flex items-center gap-2.5"
                      >
                        <div className="h-[50px] w-[50px] bg-themeColor/70 rounded-lg"></div>
                        <div className="flex flex-col">
                          <div className="text-base font-semibold">
                            {item.label}
                          </div>
                          <div className="text-[14px] text-[#A7A3A0]">
                            {item.value}
                          </div>
                        </div>
                      </div>
                    ))}
                    {
                      isEditingService &&
                      <div>
                        <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                          <div className="text-base text-black">{"Service name"}</div>
                          <Input
                            value={serviceName}
                            onChange={(e) => setServiceName(e.target.value)}
                          />
                        </div>
                        <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                          <div className="text-base text-black">{"Service price"}</div>
                          <Input
                            type="number"
                            value={servicePrice}
                            onChange={(e) => setServicePrice(e.target.value)}
                          />
                        </div>
                        <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                          <div className="text-base text-black">{"Service description"}</div>
                          <Input
                            value={serviceDesc}
                            onChange={(e) => setServiceDesc(e.target.value)}
                          />
                        </div>
                        <div className="flex items-center justify-center">
                          <button
                            className="button rounded-full !py-1 !px-5"
                            onClick={(e) => {
                              doUpdateService();
                            }}
                          >
                            Save
                          </button>
                        </div>

                      </div>
                    }
                  </div>
                </div>
                <div className="w-full max-w-[450px] flex flex-col gap-4">
                  <div className="w-full flex flex-col gap-8 grid-box px-5 py-4 rounded-[20px]">
                    <div className="w-full flex items-center">
                      <div className="text-[#18120F] text-xl font-bold flex-1">
                        Basic Details
                      </div>
                    </div>
                    <div className="w-full flex flex-col gap-3">
                      <div className="w-full flex flex-col gap-1">
                        <div className="w-full flex items-center gap-3">
                          <div className="h-7 w-7 rounded-lg bg-themeColor/70" />
                          <div className="text-[#18120F] font-semibold">
                            Tamsir Type
                          </div>
                        </div>
                        <div className="w-full flex flex-row items-center gap-2">
                          {tamsirTypes?.map(
                            (item, idx) => (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {
                                  isEditingServiceType &&

                                  <button
                                    onClick={() => deleteTamsirType(item)}
                                    style={{ alignSelf: 'flex-end', alignItems: 'flex-end', display: 'flex', flex: 1, zIndex: 1 }}
                                  >
                                    <Delete />
                                  </button>
                                }

                                <img
                                  style={{ marginTop: isEditingServiceType ? -20 : 0 }}
                                  key={idx}
                                  src={
                                    item.size ? URL.createObjectURL(item) :
                                      `${BASE_URL}/${item.path}` ||
                                      "/images/user1.png"
                                  }
                                  alt="userImage"
                                  className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                                />

                              </div>

                            )
                          )}
                          {
                            isEditingServiceType && toBeAddedTamsirTypes?.map((v, i) => {
                              return (
                                <button
                                  onClick={() => document.getElementById("upload_img").click()}

                                >
                                  <div className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center">
                                    <PlusOne />
                                    <input
                                      type="file"
                                      accept="image/*"
                                      title=""
                                      name="image"
                                      id="upload_img"
                                      className="hidden cleanbtn"
                                      onChange={(e) => {
                                        let arr = [...tamsirTypes];
                                        arr.push(e.target.files[0]);
                                        setTamsirTypes(arr);
                                        // setImg(e.target.files[0]);
                                      }}
                                    />

                                  </div>
                                </button>
                              )
                            })

                          }



                          <div>
                            <button
                              onClick={() => setIsEditingServiceType(true)}
                              className="h-[80px] min-w-[80px] flex items-center justify-center">
                              <div className="h-7 w-7 text-themeColor">
                                <EditIcon2 />
                              </div>
                            </button>
                            {
                              isEditingServiceType &&
                              <button
                                onClick={() => {
                                  setIsEditingServiceType(false);
                                  setTamsirTypes(storeServiceData?.data?.tamsir_types);
                                }}
                              >
                                <div className="text-[#18120F] font-semibold ml-4">Cancel</div>
                              </button>
                            }
                          </div>
                        </div>
                        {
                          isEditingServiceType &&

                          <div className="flex items-center justify-center">
                            <button
                              className="button rounded-full !py-1 !px-5"
                              onClick={(e) => {
                                doUpdateTamsirType();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        }

                      </div>

                      {/* TAMSIR COLOR STARTS FROM HERE */}
                      <div className="w-full flex flex-col gap-1">
                        <div className="w-full flex items-center gap-3">
                          <div className="h-7 w-7 rounded-lg bg-themeColor/70" />
                          <div className="text-[#18120F] font-semibold">
                            Tamsir Color
                          </div>
                        </div>
                        
                        <div className="w-full flex flex-row items-center gap-2">
                          {tamsirColors?.map(
                            (item, idx) => (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {
                                  isEditingServiceColor &&

                                  <button
                                    onClick={() => deleteTamsirColor(item)}
                                    style={{ alignSelf: 'flex-end', alignItems: 'flex-end', display: 'flex', flex: 1, zIndex: 1 }}
                                  >
                                    <Delete />
                                  </button>
                                }

                                <img
                                  style={{ marginTop: isEditingServiceColor ? -20 : 0 }}
                                  key={idx}
                                  src={
                                    item.size ? URL.createObjectURL(item) :
                                      `${BASE_URL}/${item.path}` ||
                                      "/images/user1.png"
                                  }
                                  alt="userImage"
                                  className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                                />

                              </div>

                            )
                          )}
                          {
                            isEditingServiceColor && toBeAddedTamsirColors?.map((v, i) => {
                              return (
                                <button
                                  onClick={() => document.getElementById("upload_img").click()}

                                >
                                  <div className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center">
                                    <PlusOne />
                                    <input
                                      type="file"
                                      accept="image/*"
                                      title=""
                                      name="image"
                                      id="upload_img"
                                      className="hidden cleanbtn"
                                      onChange={(e) => {
                                        let arr = [...tamsirColors];
                                        arr.push(e.target.files[0]);
                                        setTamsirColors(arr);
                                        // setImg(e.target.files[0]);
                                      }}
                                    />

                                  </div>
                                </button>
                              )
                            })

                          }
                          <div>
                            <button
                              onClick={() => setIsEditingServiceColor(true)}
                              className="h-[80px] min-w-[80px] flex items-center justify-center">
                              <div className="h-7 w-7 text-themeColor">
                                <EditIcon2 />
                              </div>
                            </button>
                            {
                              isEditingServiceColor &&
                              <button
                                onClick={() => {
                                  setIsEditingServiceColor(false);
                                  setTamsirColors(storeServiceData?.data?.tamsir_colors);
                                }}
                              >
                                <div className="text-[#18120F] font-semibold ml-4">Cancel</div>
                              </button>
                            }
                          </div>
                        </div>
                        {
                          isEditingServiceColor &&

                          <div className="flex items-center justify-center">
                            <button
                              className="button rounded-full !py-1 !px-5"
                              onClick={(e) => {
                                doUpdateTamsirColor();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        }

                      </div>



                      {/* 
                      <div className="w-full flex flex-col gap-1">
                        <div className="w-full flex items-center gap-3">
                          <div className="h-7 w-7 rounded-lg bg-themeColor/70" />
                          <div className="text-[#18120F] font-semibold">
                            Tamsir Color
                          </div>
                        </div>
                        <div className="w-full flex flex-row items-center gap-2">
                          {storeServiceData?.data?.tamsir_colors.map(
                            (item, idx) => (
                              <img
                                key={idx}
                                src={
                                  `${BASE_URL}/${item.path}` ||
                                  "/images/user1.png"
                                }
                                alt="userImage"
                                className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                              />
                            )
                          )}
                          <button className="h-[80px] min-w-[80px] flex items-center justify-center">
                            <div className="h-7 w-7 text-themeColor">
                              <EditIcon2 />
                            </div>
                          </button>
                        </div>
                      </div> */}


                      {/*  TAMSIR ACCESSORIES CODE */}

                      <div className="w-full flex flex-col gap-1">
                        <div className="w-full flex items-center gap-3">
                          <div className="h-7 w-7 rounded-lg bg-themeColor/70" />
                          <div className="text-[#18120F] font-semibold">
                            Tamsir Accessory
                          </div>
                        </div>
                        <div className="w-full flex flex-row items-center gap-2">
                          {tamsirAcc?.map(
                            (item, idx) => (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {
                                  isEditingServiceAcc &&

                                  <button
                                    onClick={() => deleteTamsirAcc(item)}
                                    style={{ alignSelf: 'flex-end', alignItems: 'flex-end', display: 'flex', flex: 1, zIndex: 1 }}
                                  >
                                    <Delete />
                                  </button>
                                }

                                <img
                                  style={{ marginTop: isEditingServiceAcc ? -20 : 0 }}
                                  key={idx}
                                  src={
                                    item.size ? URL.createObjectURL(item) :
                                      `${BASE_URL}/${item.path}` ||
                                      "/images/user1.png"
                                  }
                                  alt="userImage"
                                  className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                                />

                              </div>

                            )
                          )}
                          {
                            isEditingServiceAcc && toBeAddedTamsirAcc?.map((v, i) => {
                              return (
                                <button
                                  onClick={() => document.getElementById("upload_img").click()}

                                >
                                  <div className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center">
                                    <PlusOne />
                                    <input
                                      type="file"
                                      accept="image/*"
                                      title=""
                                      name="image"
                                      id="upload_img"
                                      className="hidden cleanbtn"
                                      onChange={(e) => {
                                        let arr = [...tamsirAcc];
                                        arr.push(e.target.files[0]);
                                        setTamsirAcc(arr);
                                      }}
                                    />

                                  </div>
                                </button>
                              )
                            })

                          }
                          <div>
                            <button
                              onClick={() => setIsEditingServiceAcc(true)}
                              className="h-[80px] min-w-[80px] flex items-center justify-center">
                              <div className="h-7 w-7 text-themeColor">
                                <EditIcon2 />
                              </div>
                            </button>
                            {
                              isEditingServiceAcc &&
                              <button
                                onClick={() => {
                                  setIsEditingServiceAcc(false);
                                  setTamsirAcc(storeServiceData?.data?.accessories);
                                }}
                              >
                                <div className="text-[#18120F] font-semibold ml-4">Cancel</div>
                              </button>
                            }
                          </div>
                        </div>
                        {
                          isEditingServiceAcc &&

                          <div className="flex items-center justify-center">
                            <button
                              className="button rounded-full !py-1 !px-5"
                              onClick={(e) => {
                                doUpdateTamsirAcc();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        }

                      </div>
















                      {/* TAMSIR ACC CODE ENDS HERE */}
                      {/* <div className="w-full flex flex-col gap-1">
                        <div className="w-full flex items-center gap-3">
                          <div className="h-7 w-7 rounded-lg bg-themeColor/70" />
                          <div className="text-[#18120F] font-semibold">
                            Accessory
                          </div>
                        </div>
                        <div className="w-full flex flex-row items-center gap-2">
                          {storeServiceData?.data?.accessories.map(
                            (item, idx) => (
                              <img
                                key={idx}
                                src={
                                  `${BASE_URL}/${item.path}` ||
                                  "/images/user1.png"
                                }
                                alt="userImage"
                                className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                              />
                            )
                          )}
                          <button className="h-[80px] min-w-[80px] flex items-center justify-center">
                            <div className="h-7 w-7 text-themeColor">
                              <EditIcon2 />
                            </div>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="w-full flex flex-col grid-box px-5 py-5 rounded-[20px]">
              <div className="w-full flex items-center mb-6">
                <div className="text-[#18120F] text-xl font-bold flex-1">
                  Images
                </div>
                <button className="h-7 w-7 text-themeColor">
                  <EditIcon2 />
                </button>
              </div>

              <div className="w-full flex flex-row items-center gap-4">
                {[...Array(7)].map((_, idx) => (
                  <div
                    key={idx}
                    className="h-[120px] min-w-[120px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                  >
                    <div className="h-[32px] w-[32px] text-[#D9D9D9]">
                      <GalleryIcon />
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;
