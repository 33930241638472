import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { EditIcon2, GalleryIcon } from "../../assets/Icons";
import { useFetchSingleProductQuery, useFetchSingleServiceQuery, useUpdateProductImagesMutation, useUpdateProductMutation } from "../../store/services/storeService";
import { useForceUpdate } from "../../utils/functions";
import { useParams } from "react-router-dom";
import AppLoader from "../../components/Loader/AppLoader";
import { BASE_URL } from "../../Api/constants";


import { Input } from "../../components";
import { toast } from "react-toastify";
import { Delete, PlusOne } from "@mui/icons-material";
import { useSelector } from "react-redux";


const ProductDetail = () => {


  const { id } = useParams();
  const words = useSelector((state) => state.authReducer.words);
  const forceUpdate = useForceUpdate();
  const { data: storeProductData, isLoading: storeServiceLoading } = useFetchSingleProductQuery(id);


  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const [isEditingProductImages, setIsAddingProductImages] = useState(false);


  const [productImages, setProductImages] = useState([]);
  const [deletedProductImages, setDeletedProductImages] = useState([]);


  const [serviceName, setServiceName] = useState(storeProductData?.data?.title);
  const [servicePrice, setServicePrice] = useState(storeProductData?.data?.price);
  const [serviceDesc, setServiceDesc] = useState(storeProductData?.data?.detail);

  const [updateProduct, responseProduct] = useUpdateProductMutation();
  const [updateTamsirAcc, responseTamsirAcc] = useUpdateProductImagesMutation();




  const productDetails = [
    {
      label: words["Product Name"],
      value: storeProductData?.data?.title,
    },
    {
      label: words["Price"],
      value: storeProductData?.data?.price,
    },
    {
      label: words["Description"],
      value: storeProductData?.data?.detail,
    },
  ];


  const deleteProductImage = (type) => {
    let arr = [...productImages];
    let findIndex = arr.indexOf(type);
    if (findIndex !== -1) {
      arr.splice(findIndex, 1);
      if (type.pro_img_id) {
        let arr = [...deletedProductImages];
        arr.push(type.pro_img_id);
        setDeletedProductImages(arr);
      }
      console.log('arr', arr);
      setProductImages(arr);
      forceUpdate();
    }
  }



  const doUpdateService = () => {

    const body = {
      title: serviceName,
      detail: serviceDesc,
      price: servicePrice,
      product_id: id
    }
    updateProduct(body);
  }



  const doUpdateProductImages = () => {

    const data = new FormData();
    // formData.append('deleted_ids', deletedTamsirTypes);
    for (let key of deletedProductImages) {
      data.append('deleted_ids[]', key)
    }
    let newProductImages = productImages.filter((v) => !v.id);
    for (let key of newProductImages) {
      data.append('new_images[]', key);
    }

    data.append('product_id', id.replace('pro-', ''));
    setIsAddingProductImages(false);
    updateTamsirAcc(data);
  }

  useEffect(() => {

    // console.log('responseTamsirAcc',responseTamsirAcc);
    if (responseTamsirAcc.isSuccess) {
      setDeletedProductImages([]);

      toast.success('Product images updated successfully');
    }
    if (responseTamsirAcc.isError) {
      toast.error('Error while updating product images. Please try again later');
    }
  }, [responseTamsirAcc]);






  useEffect(() => {
    setProductImages(storeProductData?.data?.productImages);
  }, [storeProductData]);


  useEffect(() => {
    if (responseProduct.isSuccess) {
      toast.success('Service updated successfully');
      setIsEditingProduct(false);
    }
    if (responseProduct.isError) {
      toast.error('Error while updating Product. Please try again later');
    }
  }, [responseProduct]);





  return (
    <>
      {
        storeServiceLoading || responseProduct.isLoading &&
        <AppLoader />
      }
      <Layout pageTitle={words["View Product"]}>
        <div className="w-full min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="w-full flex flex-col gap-4">
              <div className="w-full flex gap-4">
                <div className="w-full flex flex-col gap-8 grid-box px-5 py-5 rounded-[20px]">
                  <div className="w-full flex items-center">
                    <div className="text-[#18120F] text-xl font-bold flex-1">
                      {words["Product Details"]}
                    </div>
                    {isEditingProduct ?
                      <button
                        onClick={() => {
                          setIsEditingProduct(false);

                        }}
                      >
                        <div className="text-[#18120F] font-semibold ml-4">{words["Cancel"]}</div>
                      </button>
                      :

                      <button
                        onClick={() => setIsEditingProduct(true)}
                        className="h-7 w-7 text-themeColor">
                        <EditIcon2 />
                      </button>
                    }
                  </div>
                  {
                    isEditingProduct ?
                      <div>
                        <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                          <div className="text-base text-black">{words["Product Name"]}</div>
                          <Input
                            value={serviceName}
                            onChange={(e) => setServiceName(e.target.value)}
                          />
                        </div>
                        <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                          <div className="text-base text-black">{words["Price"]}</div>
                          <Input
                            type="number"
                            value={servicePrice}
                            onChange={(e) => setServicePrice(e.target.value)}
                          />
                        </div>
                        <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                          <div className="text-base text-black">{words["Description"]}</div>
                          <Input
                            value={serviceDesc}
                            onChange={(e) => setServiceDesc(e.target.value)}
                          />
                        </div>
                        <div className="flex items-center justify-center">
                          <button
                            className="button rounded-full !py-1 !px-5"
                            onClick={(e) => {
                              doUpdateService();
                            }}
                          >
                            {words["save"]}
                          </button>
                        </div>


                      </div>

                      :


                      <div className="w-full grid grid-cols-2 gap-5">
                        {productDetails.map((item, index) => (
                          <div
                            key={index}
                            className="w-full flex items-center gap-2.5"
                          >
                            <div className="h-[50px] w-[50px] bg-themeColor/70 rounded-lg"></div>
                            <div className="flex flex-col">
                              <div className="text-base font-semibold">
                                {item.label}
                              </div>
                              <div className="text-[14px] text-[#A7A3A0]">
                                {item.value}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                  }


                </div>




                {/* <div className="w-full max-w-[450px] flex flex-col gap-4">
                  <div className="w-full flex flex-col gap-8 grid-box px-5 py-4 rounded-[20px]">
                    <div className="w-full flex items-center">
                      <div className="text-[#18120F] text-xl font-bold flex-1">
                        Basic Details
                      </div>
                    </div>
                    <div className="w-full flex flex-col gap-3">
                      <div className="w-full flex flex-col gap-1">
                        <div className="w-full flex items-center gap-3">
                          <div className="h-7 w-7 rounded-lg bg-themeColor/70" />
                          <div className="text-[#18120F] font-semibold">
                            Tamsir Type
                          </div>
                        </div>
                        <div className="w-full flex flex-row items-center gap-2">
                          {[...Array(4)].map((_, idx) => (
                            <div
                              key={idx}
                              className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                            >
                              <div className="h-[24px] w-[24px] text-[#D9D9D9]">
                                <GalleryIcon />
                              </div>
                            </div>
                          ))}
                          <button className="h-[80px] min-w-[80px] flex items-center justify-center">
                            <div className="h-7 w-7 text-themeColor">
                              <EditIcon2 />
                            </div>
                          </button>
                        </div>
                      </div>
                      <div className="w-full flex flex-col gap-1">
                        <div className="w-full flex items-center gap-3">
                          <div className="h-7 w-7 rounded-lg bg-themeColor/70" />
                          <div className="text-[#18120F] font-semibold">
                            Tmsir Color
                          </div>
                        </div>
                        <div className="w-full flex flex-row items-center gap-2">
                          {[...Array(4)].map((_, idx) => (
                            <div
                              key={idx}
                              className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                            >
                              <div className="h-[24px] w-[24px] text-[#D9D9D9]">
                                <GalleryIcon />
                              </div>
                            </div>
                          ))}
                          <button className="h-[80px] min-w-[80px] flex items-center justify-center">
                            <div className="h-7 w-7 text-themeColor">
                              <EditIcon2 />
                            </div>
                          </button>
                        </div>
                      </div>
                      <div className="w-full flex flex-col gap-1">
                        <div className="w-full flex items-center gap-3">
                          <div className="h-7 w-7 rounded-lg bg-themeColor/70" />
                          <div className="text-[#18120F] font-semibold">
                            Accessory
                          </div>
                        </div>
                        <div className="w-full flex flex-row items-center gap-2">
                          {[...Array(4)].map((_, idx) => (
                            <div
                              key={idx}
                              className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                            >
                              <div className="h-[24px] w-[24px] text-[#D9D9D9]">
                                <GalleryIcon />
                              </div>
                            </div>
                          ))}
                          <button className="h-[80px] min-w-[80px] flex items-center justify-center">
                            <div className="h-7 w-7 text-themeColor">
                              <EditIcon2 />
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="w-full flex flex-col grid-box px-5 py-5 rounded-[20px]">
                <div className="w-full flex items-center mb-6">
                  <div className="text-[#18120F] text-xl font-bold flex-1">
                    {words["Images"]}
                  </div>
                  {isEditingProductImages ?
                    <button
                      onClick={() => {
                        setIsAddingProductImages(false);
                        setProductImages(storeProductData?.data?.productImages);
                      }}
                    >
                      <div className="text-[#18120F] font-semibold ml-4">{words["Cancel"]}</div>
                    </button>
                    :

                    <button
                      onClick={() => setIsAddingProductImages(true)}
                      className="h-7 w-7 text-themeColor">
                      <EditIcon2 />
                    </button>
                  }
                </div>


                <div className="w-full flex flex-row items-center gap-4">


                  {productImages?.map((item, idx) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {
                        isEditingProductImages &&
                        <button
                          onClick={() => deleteProductImage(item)}
                          style={{ alignSelf: 'flex-end', alignItems: 'flex-end', display: 'flex', flex: 1, zIndex: 1 }}
                        >
                          <Delete />
                        </button>
                      }

                      <img
                        style={{ marginTop: isEditingProductImages ? -20 : 0 }}
                        key={idx}
                        src={
                          item.size ? URL.createObjectURL(item) :
                            `${BASE_URL}/${item.path}` ||
                            "/images/user1.png"
                        }
                        alt="userImage"
                        className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                      />

                    </div>

                  ))}

                  {
                    isEditingProductImages &&
                    <button
                      onClick={() => document.getElementById("upload_img").click()}

                    >
                      <div className="h-[80px] min-w-[80px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center">
                        <PlusOne />
                        <input
                          type="file"
                          accept="image/*"
                          title=""
                          name="image"
                          id="upload_img"
                          className="hidden cleanbtn"
                          onChange={(e) => {
                            let arr = [...productImages];
                            arr.push(e.target.files[0]);
                            setProductImages(arr);
                            // setImg(e.target.files[0]);
                          }}
                        />

                      </div>
                    </button>
                  }



                </div>
                {
                  isEditingProductImages &&
                  <div className="flex items-center justify-center">
                    <button
                      className="button rounded-full !py-1 !px-5"
                      onClick={(e) => {
                        doUpdateProductImages()
                      }}
                    >
                      {words["save"]}
                    </button>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProductDetail;
