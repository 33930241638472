import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const bookingService = createApi({
  reducerPath: "booking",
  tagTypes: "bookings",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createBooking: builder.mutation({
        query: (data) => {
          return {
            url: "/api/booking/store",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["bookings"],
      }),
      markPaymentAsPaid: builder.mutation({
        query: (id) => {
          return {
            url: "/api/admin/mark_payment_as_paid/"+id,
            method: "POST",
            body: {},
          };
        },
        invalidatesTags: ["bookings"],
      }),
      getBookings: builder.query({
        query: () => {
          return {
            url: "/api/orders/allorders",
            method: "GET",
          };
        },
        providesTags: ["bookings"],
      }),
      getFinance: builder.query({
        query: () => {
          return {
            url: "/api/admin/finance",
            method: "GET",
          };
        },
        providesTags: ["bookings"],
      }),
      getSingleBooking: builder.query({
        query: (id) => {
          return {
            url: `/api/orders/find/${id}`,
            method: "GET",
          };
        },
        providesTags: ["bookings"],
      }),
    };
  },
});
export const {
  useCreateBookingMutation,
  useMarkPaymentAsPaidMutation,
  useGetBookingsQuery,
  useGetFinanceQuery,
  useGetSingleBookingQuery,
} = bookingService;
export default bookingService;
