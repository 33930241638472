import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import NotificationModel from "../../components/NotificationModel";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { useGetNotificationsQuery } from "../../store/services/notificationService";
import DataTable from "../../components/DataTable";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { useSelector } from "react-redux";

const Notification = () => {


  const words = useSelector((state) => state.authReducer.words);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [stores, setStores] = useState([]);

  const [activeTab, setActiveTab] = useState("users");




  // const { data: notificationsList, isLoading: bookingLoading, isSuccess: success, isError: error } = useGetNotificationsQuery();
  const { data: notificationsList, isLoading } = useGetNotificationsQuery();




  const columns = [
    {
      title: words['id'],
      dataIndex: 'user_id',
      key: 'user_id',
    },
    {
      title: words['Title'],
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: words['Description'],
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: words['User name'],
      // dataIndex: 'username',
      key: 'transaction_date',
      render: (_, record) => (
        <div>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {record.user?.name}
          </div>
        </div>
      ),
    },
    {
      title: words['User phone'],
      // dataIndex: 'username',
      key: 'username',
      render: (_, record) => (
        <div>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {record.user?.phone_number}
          </div>
        </div>
      ),
    },


  ];



  const store_columns = [
    {
      title: words['id'],
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: words['Title'],
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: words['Description'],
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: words['store name'],
      // dataIndex: 'username',
      key: 'transaction_date',
      render: (_, record) => (
        <div>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {record.user?.store_name}
          </div>
        </div>
      ),
    },
    {
      title: words['store phone'],
      // dataIndex: 'username',
      key: 'storephone',
      render: (_, record) => (
        <div>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {record.user?.store_number}
          </div>
        </div>
      ),
    },
  ];


  const getUsers = async () => {

    const onSuccess = (res) => {
      setUsers(res.users);
      setStores(res.stores);
    }

    const onError = (err) => {
    }
    await postRequest('', routes.getUsersAndStores, true, onSuccess, onError);
  };

  useEffect(() => {
    getUsers();
  }, [])




  return (
    <Layout pageTitle={words["Notification"]}>


      <div className="flex w-full border-b-2 border-b-[#EFDBC4] border-transparent " style={{ marginLeft: 35 }}>
        <button
          className={`btn text-themeColor font-semibold rounded ${activeTab === "users" ? " bg-[#EFDBC4]" : ""
            }`}
          onClick={(e) => setActiveTab("users")}
        >
          {words["users"]}
        </button>
        <button
          className={`btn text-themeColor font-semibold rounded ${activeTab === "stores" ? " bg-[#EFDBC4]" : ""
            }`}
          onClick={(e) => setActiveTab("stores")}
        >
          {words['stores']}
        </button>
      </div>
      {
        activeTab == 'users' &&
        <div className="notification-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                {/* <div className="flex flex-1">
                <SearchBar />
              </div> */}
                <div className="flex flex-1 justify-end">
                  <button
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => setOpenModal(true)}
                    style={{ zIndex: 1 }}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">{words["Add"]}</label>
                  </button>
                </div>
              </div>
              <div style={{ marginTop: -50 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%", }}
                  data={notificationsList?.user_notifications ?? []} columns={columns} />
              </div>


            </div>
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <NotificationModel
              users={users ?? []}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
          <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
            <DeleteConfirmationModel
              openModal={openDeleteModal}
              setOpenModal={setOpenDeleteModal}
            />
          </Modal>
        </div>
      }

      {
        activeTab == 'stores' &&
        <div className="notification-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                {/* <div className="flex flex-1">
                <SearchBar />
              </div> */}
                <div className="flex flex-1 justify-end">
                  <button
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => setOpenModal(true)}
                    style={{ zIndex: 1 }}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">{words["Add"]}</label>
                  </button>
                </div>
              </div>
              <div style={{ marginTop: -50 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%", }}
                  data={notificationsList?.store_notifications ?? []} columns={store_columns} />
              </div>


            </div>
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <NotificationModel
              type="stores"
              users={stores ?? []}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
          <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
            <DeleteConfirmationModel
              openModal={openDeleteModal}
              setOpenModal={setOpenDeleteModal}
            />
          </Modal>
        </div>
      }



    </Layout>
  );
};

export default Notification;



// import React, { useState } from "react";
// import Layout from "../../components/Layout";
// import { DotMenuIcon } from "../../assets/Icons";
// import SearchBar from "../../components/SearchBar";
// import Modal from "../../components/Modal";
// import NotificationModel from "../../components/NotificationModel";
// import { useSelector } from "react-redux";

// const Notification = () => {
//   const [openModal, setOpenModal] = useState(false);

//   const words = useSelector((state) => state.authReducer.words);

//   const data = [
//     {
//       title: "Example title notification!",
//       desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Watch new ads for new cars.",
//       date: "21/11/2023",
//       status: "Active",
//     },
//     {
//       title: "Example title notification!",
//       desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Watch new ads for new cars.",
//       date: "21/11/2023",
//       status: "Active",
//     },
//     {
//       title: "Example title notification!",
//       desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Watch new ads for new cars.",
//       date: "21/11/2023",
//       status: "Active",
//     },
//     {
//       title: "Example title notification!",
//       desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Watch new ads for new cars.",
//       date: "21/11/2023",
//       status: "Inactive",
//     },
//   ];
//   return (
//     <Layout pageTitle={words['Notification']}>
//       <div className="notification-page min-h-screen flex">
//         <div className="wrap wrapWidth flex flex-col">
//           <div className="flex flex-col gap-5 mt-6">
//             <div className="flex items-center justify-between gap-3">
//               <div className="flex flex-1">
//                 <SearchBar />
//               </div>
//               <div className="flex flex-1 justify-end">
//                 <button
//                   className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
//                   onClick={(e) => setOpenModal(true)}
//                 >
//                   <div className="flex items-center justify-center text-white font-normal text-2xl">
//                     +
//                   </div>
//                   <label className="text-white cursor-pointer">{words['Add']}</label>
//                 </button>
//               </div>
//             </div>
//             <div className="responsive-table overflow-x-auto">
//               <table className="min-w-full divide-y divide-gray-200">
//                 <thead className="bg-[#F5F7FB]">
//                   <tr>
//                     <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
//                       {words['no']}
//                     </th>

//                     <th className="px-2 py-2 text-left text-sm text-black font-medium">
//                       {words['Title']}
//                     </th>
//                     <th className="px-2 py-2 text-left w-52 break-words text-sm text-black font-medium">
//                       {words['Description']}
//                     </th>
//                     <th className="px-2 py-2 text-left text-sm text-black font-medium">
//                       {words['Date']}
//                     </th>
//                     <th className="px-2 py-2 text-left text-sm text-black font-medium">
//                       {words['status']}
//                     </th>
//                     <th className="px-2 py-2 text-left text-sm text-black font-medium">
//                       {words['action']}
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody className="bg-white divide-y divide-gray-200">
//                   {data.map((item, index) => (
//                     <tr
//                       key={index}
//                       className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"
//                         }`}
//                     >
//                       <td className="px-2 py-2 text-sm text-black font-medium">
//                         {index + 1}
//                       </td>
//                       <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
//                         {item.title}
//                       </td>
//                       <td className="px-2 py-2 w-52 text-xs text-[#292D32]">
//                         <div className="flex gap-3 break-words w-52">
//                           {item.desc}
//                         </div>
//                       </td>
//                       <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
//                         {item.date}
//                       </td>

//                       <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
//                         <div
//                           className={`py-2 px-2 rounded-full text-center font-semibold ${item.status === "Active"
//                               ? "bg-[#0DB214]/40 text-[#0DB214]"
//                               : "bg-red-200 text-red-400"
//                             }`}
//                         >
//                           {item.status}
//                         </div>
//                       </td>
//                       <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
//                         <div className="flex items-center justify-center cursor-pointer">
//                           <DotMenuIcon />
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//         <Modal open={openModal} setOpen={setOpenModal}>
//           <NotificationModel
//             openModal={openModal}
//             setOpenModal={setOpenModal}
//           />
//         </Modal>
//       </div>
//     </Layout>
//   );
// };

// export default Notification;
