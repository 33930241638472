
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const notificationService = createApi({
  reducerPath: "notifications",
  tagTypes: "notifications",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      // const token = reducers?.authReducer?.token;
      // const token = localStorage.getItem('admin-token');
      const token = reducers?.authReducer?.adminToken;

      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {

      getNotifications: builder.query({
        query: () => {
          return {
            url: "/api/admin/notifications/get_notifications",
            method: "GET",
          };
        },  
        providesTags: ["notifications"],
      }),

      sendNotification: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/notifications/send_notification",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["notifications"],
      }),

    };
  },
});
export const { useGetNotificationsQuery, useSendNotificationMutation } = notificationService;
export default notificationService;
