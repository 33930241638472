import React, { useEffect, useState } from "react";
import Select from "react-select";
import { CrossIcon, ImageIcon } from "../assets/Icons";
import { useCreateBannerMutation, useUpdateBannerMutation } from "../store/services/bannerService";
import { BASE_URL } from "../Api/constants";
import { capitalizeFirstLetter } from "../utils/functions";
import { useSelector } from "react-redux";

const EditBannerModel = ({ openModal, setOpenModal, data }) => {

  // alert(data.banner_id)
  const words = useSelector((state) => state.authReducer.words)
  const [img, setImg] = useState(data.image);
  const [selectedOption, setSelectedOption] = useState({ value: data.type, label: capitalizeFirstLetter(data.type) });
  const [submitData, response] = useUpdateBannerMutation();
  const [localImg, setLocalImg] = useState();


  // console.log("Banner Store Resp...", response);
  const options = [
    { value: "store", label: words["Store"] },
    { value: "user", label: words["User"] },
  ];



  const submitFormData = () => {
    const body = new FormData();
    body.append("type", selectedOption.label);
    body.append("status", "Active");
    localImg && body.append("image", localImg);
    submitData({ data: body, id: data.banner_id });
  };



  useEffect(() => {
    if (response?.isSuccess) {
      setOpenModal(false);
      window.location.reload();
    }
  }, [response]);



  return (
    <div className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
      <div className="flex items-center justify-between gap-3">
        <h1 className="text-black text-xl font-medium">{words['Edit Banner']}</h1>
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">{words['type']}</label>
          <Select
            defaultValue={selectedOption}
            value={selectedOption}
            onChange={setSelectedOption}
            options={options}
            className="w-full"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">{words['image']}</label>
          <div className="flex items-center">
            <div className="field flex flex-col">
              <div
                className="img-box flex items-center justify-center h-40 w-64 bg-[#EFEFEF] border border-[#808080] rounded-lg relative cursor-pointer"
                onClick={() => document.getElementById("upload_img").click()}
              >
                {/* {img ? (
                  <img
                    alt="img"
                    src={URL.createObjectURL(img)}
                    className="h-full w-full overflow-hidden object-contain"
                  />
                ) : (
                  <div className="flex items-center justify-center h-full w-full">
                    <ImageIcon />
                  </div>
                )} */}

                {localImg ? (
                  <img
                    alt="img"
                    src={URL.createObjectURL(localImg)}
                    className="h-full w-full rounded-full object-contain"
                  />
                ) : (
                  <img
                    alt="banner image"
                    src={BASE_URL + "/" + img}
                    className="h-full w-full rounded-full object-contain"
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  title="image"
                  id="upload_img"
                  className="hidden cleanbtn"
                  onChange={(e) => {
                    setLocalImg(e.target.files[0]);
                  }}
                />


                <input
                  type="file"
                  accept="image/*"
                  title=""
                  name="image"
                  id="upload_img"
                  className="hidden cleanbtn"
                  onChange={(e) => {
                    setImg(e.target.files[0]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <button
            className="button rounded-full !py-2 !px-10"
            onClick={(e) => submitFormData()}
          >
            {words['save']}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditBannerModel;
