import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import General from "./General";
import StoreInformation from "./StoreInformation";
import ServiceList from "./ServiceList";
import ProductsList from "./ProductsList";
import {
  useFetchStoreQuery,
  useFetchStoreServicesQuery,
  useFetchStoreProductsQuery,
} from "../../store/services/storeService";
import { useSelector } from "react-redux";

const StoreDetail = () => {

  const { id } = useParams();
  const words = useSelector((state) => state.authReducer.words);
  const { data: storeData, isLoading } = useFetchStoreQuery(id);
  const { data: storeServicesData, isLoading: storeServiceLoading } =
    useFetchStoreServicesQuery(id);
  const { data: storeProductsData, isLoading: storeProductLoading } =
    useFetchStoreProductsQuery(id);
  // console.log("storeServicesData....", storeServicesData);

  // console.log('storeProductsData', storeProductsData);
  const tabs = [
    {
      label: words["general"],
      value: "general",
    },
    {
      label: words["store information"],
      value: "store-information",
    },

    {
      label: words["service list"],
      value: "service-list",
    },
    {
      label: words["product list"],
      value: "products-list",
    },
    // {
    //   label: "Booking list",
    //   value: "booking-list",
    // },
    // {
    //   label: "Reviews",
    //   value: "reviews",
    // },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].value);


  return (
    <Layout
      pageTitle={words["store details"]}
    >
      <div className="w-full min-h-screen flex">
        {isLoading || storeServiceLoading || storeProductLoading ? (
          <div className="wrap wrapWidth flex flex-col items-center ">
            <Loader />
          </div>
        ) : (
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-6 mt-6">
              <div className="flex w-full border-b-2 border-b-[#EFDBC4] border-transparent ">
                {tabs.map((item, index) => (
                  <button
                    key={index}
                    className={`btn font-semibold rounded ${activeTab === item.value
                      ? " bg-[#D0934D] text-white"
                      : "bg-transparent text-[#D0934D]"
                      }`}
                    onClick={(e) => setActiveTab(item.value)}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
              {activeTab === "general" && <General data={storeData?.statics} />}
              {activeTab === "store-information" && (
                <StoreInformation data={storeData?.data} />
              )}
              {activeTab === "service-list" && (
                <ServiceList data={storeData?.services_list} />
              )}
              {activeTab === "products-list" && (
                <ProductsList data={storeProductsData?.data} />
              )}
              {activeTab === "reviews" && <ProductsList />}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default StoreDetail;
