
import React, { useState, } from 'react';
import { GoogleMap, MarkerF, } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px'
};


const ShowableMap = ({ lat, lng, onLocationFetch = () => { } }) => {


    return (
        <div style={{ width: "100%" }}>

            <GoogleMap
                options={{
                    disableDefaultUI: true,
                    draggable: false, // Disable map dragging
                    zoomControl: false, // Disable zoom control
                    scrollwheel: false, // Disable scroll wheel
                    disableDoubleClickZoom: false, // Disable double-click zoom

                }}
                mapContainerStyle={containerStyle}
                center={{
                    lat: parseFloat(lat),
                    lng: parseFloat(lng)
                }}
                zoom={10}
            >
                <MarkerF
                    position={{
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    }}
                    draggable={false}
                />





            </GoogleMap>






        </div >
    );
};

export default ShowableMap;
