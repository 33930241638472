import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import {
  CrossIcon,
  DotMenuIcon,
  IncomeIcon,
  StoreIcon,
  UsersIcon,
} from "../../assets/Icons";

import { useGetStoresQuery } from "../../store/services/storeService";
import { useGetUsersQuery } from "../../store/services/userService";
import { useGetGeneralQuery } from "../../store/services/settingService";
import { BASE_URL } from "../../Api/constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import Modal from "../../components/Modal";

const Main = () => {

  const words = useSelector((state) => state.authReducer.words)
  const lang = useSelector((state) => state.authReducer.lang)
  const classTextAlign = lang == 'ar' ? 'text-right' : 'text-left';

  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedStoreId, setDeletedStoreId] = useState('');
  const [loading, setLoading] = useState(false);


  let { data: storesList, isLoading: storesLoading } = useGetStoresQuery();
  // console.log("storesList....", storesList);
  const { data: usersList, isLoading: usersLoading } = useGetUsersQuery();
  // console.log("usersList....", usersList);

  const { data: generalData, isLoading: generalLoading } = useGetGeneralQuery();
  // console.log("generalData....", generalData?.data);

  const TableRow = ({ item, index }) => {

    const [hide, setHide] = useState(false);
    useEffect(() => {
      document.body.addEventListener("click", () => {
        document.body.style.overflowY = "auto";
        setHide(false);
      });
    }, []);
    return (
      <tr
        key={index}
        className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
      >
        <td className="px-2 py-2 text-sm text-black font-medium">
          {index + 1}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div className="flex items-center gap-2">
            <img
              src={
                `${BASE_URL}/${item.logo}` ||
                "/images/user1.png"
              }
              alt="userImage"
              className="h-8 w-8 rounded-full object-contain bg-gray-300"
            />
            {item.store_name}
          </div>
        </td>
        <td className={`px-2 py-2 ${classTextAlign} whitespace-nowrap text-xs text-[#292D32]`}>
          {item.store_number}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.store_email}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.store_id}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.current_location}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32] text-center">
          {item.id}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div
            className={`py-2 px-2 rounded-full text-center font-semibold ${item.status == true || item.status == "true"
              ? "bg-[#0DB214]/40 text-[#0DB214]"
              : "bg-red-200 text-red-400"
              }`}
          >
            {item.status == "true" || item.status == true ? "Active" : "InActive"}
          </div>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32] relative">
          <div className="">
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setHide(!hide);
              }}
            >
              <DotMenuIcon />
            </div>
            <div
              className={`menuList-block flex items-center absolute ${hide ? "show" : ""
                }`}
            >
              <div className="menu-item flex items-center flex-col anim">
                <Link
                  to={`/dashboard/store-detail/${item.store_id}`}
                  className="slt flex items-center gap-1 justify-between text-white text-sm"
                  onClick={(e) => {
                    setHide(!hide);
                  }}
                >
                  View
                </Link>
                <Link
                  to={`/dashboard/edit-store/${item.store_id}`}
                  className="slt flex items-center gap-1 justify-between text-white text-sm"
                  onClick={(e) => {
                    setHide(!hide);
                  }}
                >
                  Edit
                </Link>
                <div
                  className="slt flex items-center gap-1 justify-between text-white text-sm"
                  // onClick={(e) => {
                  //   setHide(!hide);
                  // }}
                  onClick={(e) => {
                    setHide(!hide);
                    setDeleteModal(true);
                    setDeletedStoreId(item.store_id);
                  }}
                >
                  Delete
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const doDeleteStore = async () => {

    setDeleteModal(false);
    setLoading(true);
    const onSuccess = () => {
      toast.success('Store deleted');
      window.location.reload();
      // getStores();
      // setLoading(false);
    }

    const onError = () => {
      toast.error('Error while deleting store');
      setLoading(false);
    }

    await postRequest({ id: deletedStoreId }, routes.deleteStore, true, onSuccess, onError);


  }

  // const getStores = async () => {

  //   const onSuccess = (res) => {
  //     setLoading(false);
  //     setTempData(res.data);
  //     setStoresList(res.data);
  //   }
  //   const onError = (err) => {
  //     setLoading(false);
  //     toast.error('Error while fetching data');
  //   }

  //   setLoading(true);
  //   await getRequest('', routes.getStores, true, onSuccess, onError);
  // }






  return (
    <Layout pageTitle={words["dashboard"]} >
      <div className="lading-page h-screen flex">
        {storesLoading || usersLoading || generalLoading ? (
          <div className="wrap wrapWidth flex flex-col items-center ">
            <Loader />
          </div>
        ) : (
          <div className="wrap wrapWidth flex flex-col">
            <div className="home-grid w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mb-8">
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#47A000]/20 h-24 w-24 rounded-2xl">
                  <UsersIcon />
                </div>
                <div

                  className="flex flex-col gap-6 py-2">

                  <p className="text-[#696974] text-xs">{words["users"]} </p>
                  <h2 className="text-[#171725] text-2xl font-medium">
                    {generalData?.data?.total_user.toLocaleString()}
                  </h2>
                </div>
              </div>
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#0066A0]/20 h-24 w-24 rounded-2xl">
                  <StoreIcon />
                </div>
                <div className="flex flex-col gap-6 py-2">
                  <p className="text-[#696974] text-xs">{words["stores"]} </p>
                  <h2 className="text-[#171725] text-2xl font-medium">
                    {generalData?.data?.total_store.toLocaleString()}
                  </h2>
                </div>
              </div>
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#0096A0]/20 h-24 w-24 rounded-2xl">
                  <div className="text-[#0096A0]">
                    <IncomeIcon />
                  </div>
                </div>
                <div className="flex flex-col gap-6 py-2">
                  <p className="text-[#696974] text-xs">{words["income"]} </p>
                  <h2 className="text-[#171725] text-2xl font-medium">
                    {generalData?.data?.total_income.toLocaleString()}
                  </h2>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 mb-8">
              <h1 className="text-[#18120F] text-2xl font-medium">{words["stores"]} </h1>
              <div className="responsive-table overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#F5F7FB]">
                    <tr>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm font-medium text-black tracking-wider`}>
                        {words["no"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} w-56 text-sm text-black font-medium`}>
                        {words["store name"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["phone"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["email"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["id store"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["location"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["publishing"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["status"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["action"]}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {storesList?.data?.map((item, index) => {
                      if (index > 9) return;
                      return (
                        <TableRow item={item} index={index} />
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h1 className="text-[#18120F] text-2xl font-medium">{words["users"]} </h1>
              <div className="responsive-table overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#F5F7FB]">
                    <tr>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm font-medium text-black tracking-wider`}>
                        {words["no"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["image"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["id"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["name"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["phone"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["email"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["type"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["status"]}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>
                        {words["action"]}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {usersList?.data?.map((item, index) => {
                      if (index > 9) return;
                      return (
                        <tr
                          key={index}
                          className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"
                            }`}
                        >
                          <td className="px-2 py-2 text-sm text-black font-medium">
                            {index + 1}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            <div className="flex items-center gap-2">
                              <img
                                src="/images/user1.png"
                                alt="userImage"
                                className="h-8 w-8 rounded-full object-contain bg-gray-300"
                              />
                            </div>
                          </td>
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {item.id}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            {item.name}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            {item.phone_number}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            {item.email}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            <div
                              className={`py-2 px-2 rounded-full text-start capitalize font-semibold ${item.role === "user"
                                ? " text-[#0DB214]"
                                : " text-[#F49A40]"
                                }`}
                            >
                              {item.role}
                            </div>
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            <div
                              className={`py-2 px-2 rounded-full text-center font-semibold ${item.status == 1
                                ? "bg-[#0DB214]/40 text-[#0DB214]"
                                : "bg-red-200 text-red-400"
                                }`}
                            >
                              {item.status == 1 ? "Active" : "InActive"}
                            </div>
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            <div className="flex items-center justify-center cursor-pointer">
                              <DotMenuIcon />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal open={deleteModal} setOpen={setDeleteModal}>
        <div className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
          <div className="flex items-center justify-between gap-3">
            <h1 className="text-black text-xl font-medium">{words['Are you sure you want to delete this store?']}</h1>
            <div
              className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
              onClick={() => setDeleteModal(false)}
            >
              <CrossIcon />
            </div>
          </div>
          <div className="flex row mt-5 justify-end " >
            <div className="flex items-center justify-center">
              <button
                className="button rounded-full !py-2 !px-10"
                onClick={(e) => doDeleteStore()}
              >
                Yes
              </button>
            </div>
            <div className="flex items-center justify-center">
              <button
                onClick={() => setDeleteModal(false)}
                className="button rounded-full !py-2 !px-10 ml-2 "
              // onClick={(e) => submitFormData()}
              >
                Cancel
              </button>
            </div>
          </div>

        </div>
      </Modal>



    </Layout>
  );
};

export default Main;
