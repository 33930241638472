import { configureStore } from "@reduxjs/toolkit";

import authService from "./services/authService";
import storeService from "./services/storeService";
import userService from "./services/userService";
import bannerService from "./services/bannerService";
import featuresService from "./services/featuredService";
import settingService from "./services/settingService";
import bookingService from "./services/bookingService";

import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";
import notificationService from "./services/notificationService";

const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [storeService.reducerPath]: storeService.reducer,
    [userService.reducerPath]: userService.reducer,
    [bannerService.reducerPath]: bannerService.reducer,
    [featuresService.reducerPath]: featuresService.reducer,
    [settingService.reducerPath]: settingService.reducer,
    [bookingService.reducerPath]: bookingService.reducer,
    [notificationService.reducerPath]: notificationService.reducer,

    authReducer: authReducer,
    globalReducer: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authService.middleware,
      storeService.middleware,
      userService.middleware,
      bannerService.middleware,
      featuresService.middleware,
      settingService.middleware,
      bookingService.middleware,
      notificationService.middleware,
    ]),
});

export default Store;
