import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const featuresService = createApi({
  reducerPath: "featured",
  tagTypes: "features",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      
      createFeature: builder.mutation({
        query: (data) => {
          return {
            url: "/api/features/store",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["features"],
      }),

      updateRank: builder.mutation({
        query: (data) => {
          return {
            url: "/api/features/update_rank",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["features"],
      }),
      getFeatures: builder.query({
        query: () => {
          return {
            url: "/api/features",
            method: "GET",
          };
        },
        providesTags: ["features"],
      }),
    };
  },
});
export const { useCreateFeatureMutation,useUpdateRankMutation, useGetFeaturesQuery } =
  featuresService;
export default featuresService;
