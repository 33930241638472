import React from "react";

function EditIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 35 35"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.041 2.917h-2.916c-7.292 0-10.209 2.916-10.209 10.208v8.75c0 7.292 2.917 10.208 10.209 10.208h8.75c7.291 0 10.208-2.916 10.208-10.208v-2.917"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M23.392 4.404L11.9 15.896c-.437.437-.875 1.298-.962 1.925l-.628 4.39c-.233 1.589.89 2.697 2.48 2.479l4.39-.628c.612-.087 1.472-.524 1.924-.962l11.492-11.492c1.983-1.983 2.917-4.287 0-7.204s-5.22-1.983-7.204 0zM21.744 6.052a10.419 10.419 0 007.204 7.204"
      ></path>
    </svg>
  );
}

export default EditIcon2;
