import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Button, Typography } from "antd";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { DotMenuIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import MenuDropDown from "../../components/MenuDropDown";
import { Link, useNavigate } from "react-router-dom";
import { useGetBookingsQuery } from "../../store/services/bookingService";
import { capitalizeFirstLetter } from "../../utils/functions";
import DataTable from "../../components/DataTable";
import { useSelector } from "react-redux";

const Booking = () => {

  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const { data: bookingList, isLoading: bookingLoading } =
    useGetBookingsQuery();
  const [bookings, setBookings] = useState([]);

  const menuList = [
    {
      item: (
        <Link
          to="/dashboard/booking/1"
          className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
        >
          View
        </Link>
      ),
    },
    // {
    //   item: (
    //     <Link
    //       to="#"
    //       className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
    //     >
    //       Edit
    //     </Link>
    //   ),
    // },
    // {
    //   item: (
    //     <Link
    //       to="#"
    //       className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
    //     >
    //       Delete
    //     </Link>
    //   ),
    // },
  ];

  


  const columns = [
    {
      title: words['Order Id'],
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: words['User name'],
      dataIndex: 'user.name',
      key: 'name',
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]"
        >
          {capitalizeFirstLetter(record.user?.name ?? "")}
        </div>
      )
    },
    {
      title: words['User phone'],
      dataIndex: 'user.phone_number',
      key: 'name',
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]"
        >
          {capitalizeFirstLetter(record.user?.phone_number ?? "")}
        </div>
      )
    },
    {
      title: words['store name'],
      dataIndex: 'store?.store_name',
      key: 'store_name',
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]"
        >
          {capitalizeFirstLetter(record.store?.store_name ?? "")}
        </div>
      )
    },
    {
      title: words['Admin commision'],
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: words['Paymnet status'],
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (_, record) => (
        <div
          className={`py-2 px-0 rounded-full text-center font-semibold ${record.payment_status?.toLowerCase() === "completed"
            ? "bg-[#0DB214]/40 text-[#0DB214]"
            : "bg-red-200 text-red-400"
            }`}
        >
          {capitalizeFirstLetter(record.payment_status)}
        </div>
      )
    },
    {
      title: words['Date'],
      dataIndex: 'date',
      key: 'date',

    },

    {
      title: words["action"],
      key: 'action',
      render: (_, record) => (
        <Dropdown
          overlay={


            <Menu
              onClick={({ key }) => {
                navigate('/dashboard/booking/' + record.order_id)
              }}
            >
              <>
                <Menu.Item key="view">{words["View"]}</Menu.Item>
                {/* <Menu.Item key="in-review">In-Review</Menu.Item> */}
              </>

            </Menu>
          }
          trigger={['click']}
        >
          <Button>
            <div
              className="flex items-center justify-center cursor-pointer text-[#0025F2]"
            >
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),

    },


    // {
    //   title: 'Transaction date',
    //   dataIndex: 'transaction_date',
    //   key: 'transaction_date',
    //   render: (_, record) => (
    //     <div>
    //       <div
    //         className="px-2 py-2 text-left text-sm text-black font-medium"
    //         style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    //         {getDateString(record.created_at)}
    //       </div>
    //     </div>
    //   ),
    // },

  ];



  return (
    <Layout>
      <div className="booking-page min-h-screen flex">
        {bookingLoading ? (
          <div className="wrap wrapWidth flex flex-col items-center ">
            <Loader />
          </div>
        ) : (
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                {/* <div className="flex flex-1">
                  <SearchBar />
                </div> */}
                <div className="flex flex-1"></div>
              </div>
              <div className="responsive-table overflow-x-auto">
                {
                  bookingList?.data?.length &&
                  <div style={{ marginTop: 0 }}>
                    <DataTable
                      sortKey={'id'}
                      sortDirection={'descending'}
                      searchContainerStyle={{ width: "40%", }}
                      data={bookingList?.data ? bookingList.data : []} columns={columns} />
                  </div>

                }
                {!bookingList?.data?.length ? (
                  <div className="flex items-center justify-center text-center w-full min-h-[100px] min-w-[300px]">
                    Data not found!
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Booking;
