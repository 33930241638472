import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { CrossIcon, DotMenuIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import { useGetStoresQuery } from "../../store/services/storeService";
import { BASE_URL } from "../../Api/constants";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal";
import BannerModel from "../../components/BannerModel";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../Api";
import { routes } from "../../Api/routes";

const Stores = () => {

  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);
  const classTextAlign = lang == 'ar' ? 'text-right' : 'text-left';
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedStoreId, setDeletedStoreId] = useState('');

  const [loading, setLoading] = useState(false);
  const [storesList, setStoresList] = useState([]);
  // const { data: storesList, isLoading: storesLoading } = useGetStoresQuery();

  const [tempData, setTempData] = useState(storesList);

  const doDeleteStore = async () => {

    setDeleteModal(false);
    setLoading(true);
    const onSuccess = () => {
      toast.success('Store deleted');
      getStores();
      // setLoading(false);
    }

    const onError = () => {
      toast.error('Error while deleting store');
      setLoading(false);
    }

    await postRequest({ id: deletedStoreId }, routes.deleteStore, true, onSuccess, onError);


  }

  const getStores = async () => {

    const onSuccess = (res) => {
      setLoading(false);
      setTempData(res.data);
      setStoresList(res.data);
    }
    const onError = (err) => {
      setLoading(false);
      toast.error('Error while fetching data');
    }

    setLoading(true);
    await getRequest('', routes.getStores, true, onSuccess, onError);
  }


  const TableRow = ({ item, index }) => {
    const [hide, setHide] = useState(false);
    useEffect(() => {
      document.body.addEventListener("click", () => {
        document.body.style.overflowY = "auto";
        setHide(false);
      });
    }, []);
    return (
      <tr
        key={index}
        className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
      >
        <td className="px-2 py-2 text-sm text-black font-medium">
          {index + 1}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div className="flex items-center gap-2">
            <img
              src={
                `${BASE_URL}/${item.logo}` ||
                "/images/user1.png"
              }
              alt="userImage"
              className="h-8 w-8 rounded-full object-contain bg-gray-300"
            />
            {item.store_name}
          </div>
        </td>
        <td className={`px-2 py-2 ${classTextAlign} whitespace-nowrap text-xs text-[#292D32]`}>
          {item.store_number}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.store_email}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.store_id}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.current_location}
        </td>
        <td className={`px-2 py-2 whitespace-nowrap ${classTextAlign} text-xs text-[#292D32] text-center`}>
          {item.id}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div
            className={`py-2 px-2 rounded-full text-center font-semibold ${item.status == true || item.status == "true"
              ? "bg-[#0DB214]/40 text-[#0DB214]"
              : "bg-red-200 text-red-400"
              }`}
          >
            {item.status == "true" || item.status == true ? "Active" : "InActive"}
          </div>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32] relative">
          <div className="">
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setHide(!hide);
              }}
            >
              <DotMenuIcon />
            </div>
            <div
              className={`menuList-block flex items-center absolute ${hide ? "show" : ""
                }`}
            >
              <div className="menu-item flex items-center flex-col anim">
                <Link
                  to={`/dashboard/store-detail/${item.store_id}`}
                  className="slt flex items-center gap-1 justify-between text-white text-sm"
                  onClick={(e) => {
                    setHide(!hide);
                  }}
                >
                  {words['View']}
                </Link>
                <Link
                  to={`/dashboard/edit-store/${item.store_id}`}
                  className="slt flex items-center gap-1 justify-between text-white text-sm"
                  onClick={(e) => {
                    setHide(!hide);
                  }}
                >
                  {words['Edit']}
                </Link>
                <div
                  className="slt flex items-center gap-1 justify-between text-white text-sm"
                  onClick={(e) => {
                    setHide(!hide);
                    setDeleteModal(true);
                    setDeletedStoreId(item.store_id);
                  }}
                >
                  {words['Delete']}
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };




  useEffect(() => {
    getStores();
  }, [])


  return (
    <Layout pageTitle={words['stores']}>
      <div className="store-page min-h-screen flex">
        {/* storesLoading ||  */}
        {loading ? (
          <div className="wrap wrapWidth flex flex-col items-center ">
            <Loader />
          </div>
        ) : (
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                <div className="flex flex-1">
                  <SearchBar
                    onChange={(v) => {
                      if (!v) {
                        setTempData(storesList);
                        return;
                      };
                      let arr = storesList;
                      arr = arr.filter((e) => e.store_name.toLowerCase().includes(v) || e.store_email.toLowerCase().includes(v.toLowerCase()))
                      setTempData(arr);
                    }}
                  />
                </div>
                <div className="flex flex-1 justify-end">
                  <Link
                    to="/dashboard/create-store"
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-1"
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">
                      {words['add store']}
                    </label>
                  </Link>
                </div>
              </div>
              <div className="responsive-table overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#F5F7FB]">
                    <tr>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm font-medium text-black tracking-wider`}>
                        {words['no']}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} w-56 text-sm text-black font-medium`}>
                        {words['store name']}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>

                        {words['phone']}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>

                        {words['email']}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>

                        {words['id store']}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>

                        {words['location']}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>

                        {words['publishing']}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>

                        {words['status']}
                      </th>
                      <th className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}>

                        {words['action']}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {tempData?.length && tempData?.map((item, index) => (
                      <TableRow item={item} index={index} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal open={deleteModal} setOpen={setDeleteModal}>
        <div className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
          <div className="flex items-center justify-between gap-3">
            <h1 className="text-black text-xl font-medium">{words['Are you sure you want to delete this store?']} </h1>
            <div
              className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
              onClick={() => setDeleteModal(false)}
            >
              <CrossIcon />
            </div>
          </div>
          <div className="flex row mt-5 justify-end " >
            <div className="flex items-center justify-center">
              <button
                className="button rounded-full !py-2 !px-10"
                onClick={(e) => doDeleteStore()}
              >
                {words['Yes']}
              </button>
            </div>
            <div className="flex items-center justify-center">
              <button
                onClick={() => setDeleteModal(false)}
                className="button rounded-full !py-2 !px-10 ml-2 "
              // onClick={(e) => submitFormData()}
              >
                {words['Cancel']}
              </button>
            </div>
          </div>

        </div>
      </Modal>

    </Layout>
  );
};

export default Stores;
