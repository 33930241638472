import React, { useState, useEffect } from 'react';
import { Button, MenuItem, Menu, Typography, Checkbox, ListItemText } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import axios from 'axios';
import { Layout } from '../../components';
import { toast } from 'react-toastify';
import AppLoader from '../../components/Loader/AppLoader';
import { _BASE_URL, BASE_URL } from '../../Api/constants';
import { useSelector } from 'react-redux';
import { Image } from 'antd';
import TopServices from './TopServices';
import TopProducts from './TopProducts';
import TopStores from './TopStores';

const ServicesList = () => {
    const [services, setServices] = useState([]);
    const [products, setProducts] = useState([]);
    const [stores, setStores] = useState([]);
    // const [selectedServices, setSelectedServices] = useState([]);
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [anchorEl, setAnchorEl] = useState(null);

    // const [checkedServices, setCheckedServices] = useState(new Set()); // For tracking selected checkboxes
    // const [draggingItem, setDraggingItem] = useState();

    // const words = useSelector((state) => state.authReducer.words);

    const [loading, setLoading] = useState(false);







    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(BASE_URL + '/api/admin/getStoreServicesProducts');
                setServices(response.data.services);
                setProducts(response.data.products)
                setStores(response.data.stores)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    return (
        <>
            {
                loading && <AppLoader />
            }
            <Layout>
                <TopServices
                    services={services}
                    loading={loading}
                    setLoading={setLoading}
                />

                <TopProducts
                    services={products}
                    loading={loading}
                    setLoading={setLoading}
                />
                <TopStores
                    services={stores}
                    loading={loading}
                    setLoading={setLoading}
                />

            </Layout>
        </>
    );
};

export default ServicesList;
