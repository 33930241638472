import { useSelector } from "react-redux";
import { BASE_URL } from "../../Api/constants";
import { EditIcon2, GalleryIcon } from "../../assets/Icons";
import MapComponent from "../../components/MapComponent";
import ShowableMap from "../../components/ShowableMap";

const StoreInformation = ({ data }) => {


  const words = useSelector((state) => state.authReducer.words);

  const companyDetail = [
    {
      label: words["Store Name"],
      value: `${data ? data?.store_name : "-"}`,
    },
    {
      label: words["Visiting Charge"],
      value: `${data ? data?.store_visting_charges : "-"}`,
    },
    {
      label: words["Store Name"],
      value: `${data ? data?.store_name : "-"}`,
    },
    // {
    //   label: words["Address"],
    //   value: `${data ? data?.current_location : "-"}`,
    // },
    {
      label: words["City"],
      value: `${data ? data?.city : "-"}`,
    },
    {
      label: words["State"],
      value: `${data ? data?.state : "-"}`,
    },
    {
      label: words["At Home"],
      value: `${data?.is_at_home ? "Yes" : "No"}`,
    },
    {
      label: words["At Store"],
      value: `${data?.is_at_store ? "Yes" : "No"}`,
    },
    {
      label: words["About Company"],
      value: `${data ? data?.description : "-"}`,
    },
    {
      label: words["Long Description"],
      value: `${data ? data?.description : "-"}`,
    },
  ];

  const personalInformation = [
    {
      label: words["Name"],
      value: `${data ? data?.user_name : "-"}`,
    },
    {
      label: words["Email"],
      value: `${data ? data?.store_email : "-"}`,
    },
    {
      label: words["Phone"],
      value: `${data ? data?.store_number : "-"}`,
    },
    {
      label: words["Commission"],
      value: `${data ? data?.store_visting_charges : "-"}`,
    },
  ];

  const timingDetail = [
    {
      label: words["Sunday"],
      value: `${data?.is_sunday === "true"
        ? `${data?.sunday_from} - ${data?.sunday_to}`
        : "Closed"
        }`,
    },
    {
      label: words["Monday"],
      value: `${data?.is_monday === "true"
        ? `${data?.monday_from} - ${data?.monday_to}`
        : "Closed"
        }`,
    },
    {
      label: words["Tuesday"],
      value: `${data?.is_tuesday === "true"
        ? `${data?.tuesday_from} - ${data?.tuesday_to}`
        : "Closed"
        }`,
    },
    {
      label: words["Wednesday"],
      value: `${data?.is_wednesday === "true"
        ? `${data?.wednesday_from} - ${data?.wednesday_to}`
        : "Closed"
        }`,
    },
    {
      label: words["Thursday"],
      value: `${data?.is_thursday === "true"
        ? `${data?.thursday_from} - ${data?.thursday_to}`
        : "Closed"
        }`,
    },
    {
      label: words["Friday"],
      value: `${data?.is_friday === "true"
        ? `${data?.friday_from} - ${data?.friday_to}`
        : "Closed"
        }`,
    },
    {
      label: words["Saturday"],
      value: `${data?.is_saturday === "true"
        ? `${data?.saturday_from} - ${data?.saturday_to}`
        : "Closed"
        }`,
    },
  ];

  const bankDetail = [
    {
      label: words["Bank Name"],
      value: `${data ? data?.bank_name : "-"}`,
    },
    {
      label: words["Account Number"],
      value: `${data ? data?.account_number : "-"}`,
    },
    {
      label: words["Account Name"],
      value: `${data ? data?.account_name : "-"}`,
    },
  ];


  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full flex gap-4">
        <div className="w-full flex flex-col gap-8 grid-box px-5 py-5 rounded-[20px]">
          <div className="w-full flex items-center">
            <div className="text-[#18120F] text-xl font-bold flex-1">
              {words["Company Details"]}
            </div>
            {/* <button className="h-7 w-7 text-themeColor">
              <EditIcon2 />
            </button> */}
          </div>
          <div className="w-full grid grid-cols-3 items-start gap-x-3 gap-y-4 flex-1">
            {companyDetail.map((item, index) => (
              <div key={index} className="w-full flex items-center gap-2.5">
                <div className="h-[50px] w-[50px] bg-themeColor/70 rounded-lg"></div>
                <div className="flex flex-col">
                  <div className="text-base font-semibold">{item.label}</div>
                  <div className="text-[14px] text-[#A7A3A0]">{item.value}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full flex justify-between">
            <div className="w-full flex items-center gap-6">
              <img
                alt="img"
                src={`${BASE_URL}/${data.logo}`}
                className="h-[120px] min-w-[120px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center object-fill"
              />
              {/* <div className="h-[120px] min-w-[120px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center">
                <div className="h-[32px] w-[32px] text-[#D9D9D9]">
                  <GalleryIcon />
                </div>
              </div> */}
              <div className="w-full flex flex-col">
                <div className="text-[#18120F] font-bold">{words["Logo"]} </div>
                <div className="text-[#A7A3A0]">Logo.png</div>
              </div>
            </div>
            <div className="w-full flex items-center gap-6">
              <img
                alt="img"
                src={`${BASE_URL}/${data.photo1}`}
                className="h-[120px] min-w-[120px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center object-fill"
              />
              {/* <div className="h-[120px] min-w-[120px] border-solid border-[1px] border-[#D9D9D9] rounded-[10px] flex items-center justify-center">
                <div className="h-[32px] w-[32px] text-[#D9D9D9]">
                  <GalleryIcon />
                </div>
              </div> */}
              <div className="w-full flex flex-col">
                <div className="text-[#18120F] font-bold">{words["Banner"]}</div>
                <div className="text-[#A7A3A0]">Logo.png</div>
              </div>
            </div>




          </div>
        </div>
        <div className="w-full max-w-[450px] flex flex-col gap-4">
          <div className="w-full flex flex-col gap-8 grid-box px-5 py-4 rounded-[20px]">
            <div className="w-full flex items-center">
              <div className="text-[#18120F] text-xl font-bold flex-1">
                {words["Personal Information"]}
              </div>
              {/* <button className="h-7 w-7 text-themeColor">
                <EditIcon2 />
              </button> */}
            </div>
            <div className="w-full grid grid-cols-2 gap-x-3 gap-y-6">
              {personalInformation.map((item, index) => (
                <div key={index} className="w-full flex items-center gap-2.5">
                  <div className="h-[50px] min-w-[50px] bg-themeColor/70 rounded-lg"></div>
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">{item.label}</div>
                    <div className="text-[14px] text-[#A7A3A0]">
                      {item.value}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex flex-col gap-8 grid-box px-5 py-4 rounded-[20px]">
            <div className="w-full flex items-center">
              <div className="text-[#18120F] text-xl font-bold flex-1">
                {words['Timing Details']}
              </div>
            </div>
            <div className="w-full flex flex-col gap-2">
              {timingDetail.map((item, index) => (
                <div key={index} className="w-full flex items-center">
                  <div className="text-black text-base font-semibold flex-1">
                    {item.label}
                  </div>
                  <div className="text-[#FF2525] text-base font-semibold flex-1">
                    {item.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col grid-box px-5 py-5 rounded-[20px]">
        <div className="w-full flex items-center mb-6">
          <div className="text-[#18120F] text-xl font-bold flex-1">
            {words["Bank Details"]}
          </div>
          <button className="h-7 w-7 text-themeColor">
            <EditIcon2 />
          </button>
        </div>

        <div className="w-full grid grid-cols-3 items-start gap-x-3 gap-y-4 flex-1">
          {bankDetail.map((item, index) => (
            <div key={index} className="w-full flex items-center gap-2.5">
              <div className="h-[50px] w-[50px] bg-themeColor/70 rounded-lg"></div>
              <div className="flex flex-col">
                <div className="text-base font-semibold">{item.label}</div>
                <div className="text-[14px] text-[#A7A3A0]">{item.value}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ShowableMap
        lat={data.latitude}
        lng={data.longitude}
      />


    </div>
  );
};

export default StoreInformation;
