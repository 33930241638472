import React, { useState } from "react";
import { Dropdown, Menu, Button, Typography } from "antd";
import moment from "moment";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { DotMenuIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import BannerModel from "../../components/BannerModel";
import { useDeleteBannerMutation, useGetBannersQuery } from "../../store/services/bannerService";
import { BASE_URL } from "../../Api/constants";
import { capitalizeFirstLetter } from "../../utils/functions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import EditBannerModel from "../../components/EditBannerModel";


const Banner = () => {


  const [openModal, setOpenModal] = useState(false);
  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const { data: bannerList, isLoading: bannerLoading } = useGetBannersQuery();
  const [submitData, { isLoading: deleteBannerLoading }] = useDeleteBannerMutation();


  const [editModal, setEditModal] = useState(false);
  const [editModalItem, setEditModalItem] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');


  const columns = [
    {
      title: words['id'],
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: words['type'],
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: words['image'],
      dataIndex: 'image',
      key: 'image',
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]"
        >
          <img
            src={
              `${BASE_URL}/${record.image}` ||
              ""
            }
            alt="userImage"
            className="h-12 w-28 rounded-lg bg-gray-300"
          />
        </div>
      )
    },





    {
      title: words["action"],
      key: 'action',
      render: (_, record) => (
        <Dropdown
          overlay={


            <Menu
              onClick={({ key }) => {
                if (key == 'edit') {
                  setEditModalItem(record)
                  setEditModal(true);
                }
                if (key == 'delete') {
                  setDeletedId(record.banner_id);
                  setOpenDeleteModal(true);
                }
              }}
            >
              <>
                <Menu.Item key="edit">{words['Edit']}</Menu.Item>
                <Menu.Item key="delete">{words['Delete']}</Menu.Item>
              </>

            </Menu>
          }
          trigger={['click']}
        >

          <Button>
            <div
              className="flex items-center justify-center cursor-pointer text-[#0025F2]"
            >
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const doDeleteBanner = () => {
    submitData(deletedId);
  }


  return (
    <Layout pageTitle="Banner">
      <div className="banner-page min-h-screen flex">
        {bannerLoading || deleteBannerLoading ? (
          <div className="wrap wrapWidth flex flex-col items-center ">
            <Loader />
          </div>
        ) : (
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                {/* <div className="flex flex-1">
                  <SearchBar />
                </div> */}
                <div className="flex flex-1 justify-end">
                  <button
                    style={{ zIndex: 1 }}
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => setOpenModal(true)}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">{words['Add']}</label>
                  </button>
                </div>
              </div>
              {/* <div className="responsive-table overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#F5F7FB]">
                    <tr>
                      <th className="px-2 py-2 text-left w-20 text-sm font-medium text-black tracking-wider">
                        No
                      </th>
                      <th className="px-2 py-2 text-left w-28 text-sm text-black font-medium">
                        Image
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Type
                      </th>
                     
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Created At
                      </th>
                      <th className="px-2 py-2 text-center text-sm text-black font-medium">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {bannerList?.data?.map((item, index) => (
                      <tr
                        key={index}
                        className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"
                          }`}
                      >
                        <td className="px-2 py-2 text-sm text-black font-medium">
                          {index + 1}
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                          <img
                            src={
                              `${BASE_URL}/${item.image}` ||
                              ""
                            }
                            alt="userImage"
                            className="h-12 w-28 rounded-lg bg-gray-300"
                          />
                        </td>
                        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                          {item.type}
                        </td>
                        
                        <td className="px-2 py-2 w-28 whitespace-nowrap text-xs text-[#292D32]">
                          {moment(item?.created_at).format("MMM DD, YYYY")}
                        </td>

                        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                          <div
                            className="flex items-center justify-center cursor-pointer"
                            onClick={(e) => setOpenModal(true)}
                          >
                            <DotMenuIcon />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
              <div style={{ marginTop: -50 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%", }}
                  data={bannerList?.data} columns={columns} />
              </div>
            </div>
          </div>
        )}
        <Modal open={openModal} setOpen={setOpenModal}>
          <BannerModel openModal={openModal} setOpenModal={setOpenModal} />
        </Modal>

        <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
          <DeleteConfirmationModel
            openModal={openDeleteModal}
            onSubmit={() => doDeleteBanner()}
            setOpenModal={setOpenDeleteModal}
          />
        </Modal>
        <Modal open={editModal} setOpen={setEditModal}>
          <EditBannerModel
            data={editModalItem}
            openModal={openModal} setOpenModal={setEditModal} />
        </Modal>


      </div>
    </Layout>
  );
};

export default Banner;
