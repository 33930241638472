import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Button, Typography } from "antd";

import Layout from "../../components/Layout";
import { DotMenuIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import AddUserModel from "../../components/AddUserModel";
import { useDeleteUserMutation, useGetUsersQuery } from "../../store/services/userService";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import DataTable from "../../components/DataTable";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import EditUserModel from "../../components/EditUserModel";
import { useSelector } from "react-redux";

const Users = () => {

  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang)

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');

  // { isLoading: isAddingUser }
  const { data: usersList, isLoading: userLoading } = useGetUsersQuery();
  const [editUser, setEditUser] = useState();
  const [editModal, setEditModal] = useState(false);
  const [deleteUser, deleteResponse] = useDeleteUserMutation();
  // console.log("usersList....", usersList);


  const classTextAlign = lang == 'ar' ? 'text-right' : 'text-left';

  const columns = [
    {
      title: words["id"],
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: words["Name"],
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: words["phone"],
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: words["Email"],
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: words["type"],
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => (
        <div
          className={`py-2 px-0 rounded-full text-center font-semibold ${record.status?.toLowerCase() === "published"
            ? "bg-[#0DB214]/40 text-[#0DB214]"
            : "bg-red-200 text-red-400"
            }`}
        >
          {capitalizeFirstLetter(record.role)}
        </div>
      )
    },
    {
      title: words["status"],
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div
            className={`py-2 px-2 rounded-full text-center font-semibold ${record.status == 1
              ? "bg-[#0DB214]/40 text-[#0DB214]"
              : "bg-red-200 text-red-400"
              }`}
          >
            {record.status == 1 ? "Active" : "InActive"}
          </div>
        </td>
      )
    },

    {
      title: words["Created at"],
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, record) => (
        <div
          className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {getDateString(record.created_at)}
        </div>
      ),
    },
    {
      title: words["action"],
      key: 'action',
      render: (_, record) => (
        <Dropdown
          overlay={


            <Menu
              onClick={({ key }) => {
                if (key == 'delete') {
                  setDeletedId(record.id);
                  setOpenDeleteModal(true);
                }
                if (key == 'edit') {
                  setEditUser(record);
                  setEditModal(true);
                }
                // doChangePostStatus(key, record)
              }}
            >
              <>
                <Menu.Item key="edit">{words["Edit"]}</Menu.Item>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
                {/* <Menu.Item key="in-review">In-Review</Menu.Item> */}
              </>

            </Menu>
          }
          trigger={['click']}
        >
          <Button>
            <div
              className="flex items-center justify-center cursor-pointer text-[#0025F2]"
            >
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),

    },
  ];



  const doDeleteUser = async () => {

    try {
      deleteUser(deletedId)
      // console.log('User deleted successfully');
    } catch (error) {
      // console.error('Failed to delete user:', error);
    }
  };



  useEffect(() => {
    console.log('deleteResponse', deleteResponse)
    if (deleteResponse.isSuccess) {
      toast.success("User deleted Successfully");
      setLoading(false);
      // setOpenModal(false)
      // navigate("/dashboard/stores");
    }
    if (deleteResponse.isError) {
      console.log('deleteResponse.isError', deleteResponse.isError)
      toast.error("Something wrong try again later!");
    }
  }, [deleteResponse]);


  return (
    <Layout pageTitle={words["users"]}>
      {
        loading || userLoading || deleteResponse.isLoading ? <Loader />
          :
          <div className="store-page min-h-screen flex">
            <div className="wrap wrapWidth flex flex-col">
              <div className="flex flex-col gap-5 mt-6">
                <div className="flex items-center justify-between gap-3">
                  {/* <div className="flex flex-1">
                <SearchBar />
              </div> */}
                  <div className="flex flex-1 justify-end">
                    <button
                      onClick={() => setOpenModal(true)}
                      style={{ zIndex: 1 }}
                      className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]">
                      <div className="flex items-center justify-center text-white font-normal text-2xl">
                        +
                      </div>
                      <label className="text-white cursor-pointer">{words["Add"]}</label>
                    </button>
                  </div>
                </div>
                {/* <div className="responsive-table overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#F5F7FB]">
                  <tr>
                    <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                      No
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Image
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      ID
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Name
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Phone
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Email
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Type
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Status
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {usersList?.data?.map((item, index) => (
                    <tr
                      key={index}
                      className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"
                        }`}
                    >
                      <td className="px-2 py-2 text-sm text-black font-medium">
                        {index + 1}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div className="flex items-center gap-2">
                          <img
                            src="/images/user1.png"
                            alt="userImage"
                            className="h-8 w-8 rounded-full object-contain bg-gray-300"
                          />
                        </div>
                      </td>
                      <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                        {item.id}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.name}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.phone_number}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        {item.email}
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div
                          className={`py-2 px-2 rounded-full text-start capitalize font-semibold ${item.role === "user"
                            ? " text-[#0DB214]"
                            : " text-[#F49A40]"
                            }`}
                        >
                          {item.role}
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div
                          className={`py-2 px-2 rounded-full text-center font-semibold ${item.status == 1
                            ? "bg-[#0DB214]/40 text-[#0DB214]"
                            : "bg-red-200 text-red-400"
                            }`}
                        >
                          {item.status == 1 ? "Active" : "InActive"}
                        </div>
                      </td>
                      <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                        <div className="flex items-center justify-center cursor-pointer">
                          <DotMenuIcon />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> */}
                <div style={{ marginTop: -50 }}>
                  <DataTable
                    sortKey={"id"}
                    sortDirection={"descending"}
                    searchContainerStyle={{ width: "40%", }}
                    data={usersList?.data ? usersList.data : []} columns={columns} />
                </div>
              </div>
            </div>

            {/* <Modal open={openModal} setOpen={setOpenModal}>
          <AddUserModel
            onSuccessCreate={() => getUsers()}
            openModal={openModal} setOpenModal={setOpenModal} />
        </Modal> */}


            <Modal open={openModal} setOpen={setOpenModal}>
              <AddUserModel
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
            </Modal>
            <Modal open={editModal} setOpen={setEditModal}>
              <EditUserModel
                user={editUser}
                openModal={editModal}
                setOpenModal={setEditModal}
              />
            </Modal>

            <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
              <DeleteConfirmationModel
                openModal={openDeleteModal}
                setOpenModal={setOpenDeleteModal}
                onSubmit={() => doDeleteUser()}
              />
            </Modal>


          </div>
      }
    </Layout>
  );
};

export default Users;
