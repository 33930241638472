
/* eslint-disable no-unused-vars */

import { useJsApiLoader } from '@react-google-maps/api';

import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./css/App.scss";
import "./index.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";

import Main from "./Pages/Home";
import Routing from "./routes/Routing";
import { useDispatch, useSelector } from "react-redux";
import { MAP_API_KEY } from './Api/constants';


function App() {

  const apiKey = MAP_API_KEY;


  const [openSidebar, setOpenSidebar] = useState(false);
  var activeUser = localStorage.getItem("_user");
  const [user, setUser] = useState(false);
  const lang = useSelector((state) => state.authReducer.lang);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: ['places'],
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <body dir={lang == 'en' ? "ltr" : "rtl"} >
      <Routing />;
    </body>
  )

}

export default App;
