import React from "react";

function StoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.893 10.785v4.316c0 4.316 1.73 6.046 6.047 6.046h5.18c4.317 0 6.047-1.73 6.047-6.046v-4.316"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.535 11.535c1.76 0 3.057-1.432 2.884-3.191l-.635-6.422H9.295l-.644 6.422c-.173 1.759 1.125 3.19 2.884 3.19z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.6 11.535c1.942 0 3.365-1.577 3.173-3.509l-.27-2.643c-.346-2.5-1.307-3.46-3.825-3.46h-2.932l.673 6.738c.163 1.586 1.595 2.874 3.181 2.874zM5.421 11.535c1.587 0 3.019-1.288 3.173-2.874l.211-2.125.461-4.614H6.335c-2.519 0-3.48.962-3.826 3.46l-.26 2.644c-.192 1.932 1.23 3.509 3.172 3.509zM11.535 16.341c-1.605 0-2.403.798-2.403 2.403v2.404h4.806v-2.404c0-1.605-.798-2.403-2.403-2.403z"
      ></path>
    </svg>
  );
}

export default StoreIcon;
