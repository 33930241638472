import { useSelector } from "react-redux";
import { IncomeIcon } from "../../assets/Icons";

const General = ({ data }) => {


  const words = useSelector((state) => state.authReducer.words);

  const generalList = [
    {
      label: words["orders"],
      value: `${data ? data?.orders : "00"}`,
      icon: (
        <div className="icon flex items-center justify-center bg-themeColor/20 h-24 w-24 rounded-2xl">
          <div className="text-themeColor">
            <IncomeIcon />
          </div>
        </div>
      ),
    },
    {
      label: words["products"],
      value: `${data ? data?.products : "00"}`,
      icon: (
        <div className="icon flex items-center justify-center bg-[#0096A0]/20 h-24 w-24 rounded-2xl">
          <div className="text-[#0096A0]">
            <IncomeIcon />
          </div>
        </div>
      ),
    },
    {
      label: words["income"],
      value: `${data ? data?.income : "00"}`,
      currency: words["OMR"],
      icon: (
        <div className="icon flex items-center justify-center bg-[#0096A0]/20 h-24 w-24 rounded-2xl">
          <div className="text-[#0096A0]">
            <IncomeIcon />
          </div>
        </div>
      ),
    },
    {
      label: words["evaluation"],
      value: `${data ? Number(data?.Evaluation) : "00"}`,
      icon: (
        <div className="icon flex items-center justify-center bg-themeColor/20 h-24 w-24 rounded-2xl">
          <div className="text-themeColor">
            <IncomeIcon />
          </div>
        </div>
      ),
    },
    {
      label: words["services"],
      value: `${data ? data?.services : "00"}`,
      icon: (
        <div className="icon flex items-center justify-center bg-themeColor/20 h-24 w-24 rounded-2xl">
          <div className="text-themeColor">
            <IncomeIcon />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {generalList.map((item, index) => (
        <div
          key={index}
          className="grid-box gap-6 flex border border-[#D8D8D8] p-4 rounded-2xl"
        >
          {item.icon}
          <div className="flex flex-col justify-center">
            <h2 className="text-[42px] leading-[50px] font-semibold text-themeColor">
              {item.value}
              {item.currency && (
                <span className="text-lg">{item.currency}</span>
              )}
            </h2>
            <p className="text-black text-lg">{item.label}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default General;
