import React, { useState } from "react";
import Select from "react-select";
import Layout from "../../components/Layout";
import { useGetSingleBookingQuery } from "../../store/services/bookingService";
import Loader from "../../components/Loader";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const BookingDetail = () => {

  const words = useSelector((state) => state.authReducer.words);
  const bookingData = [{}, {}, {}];
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState(null);
  const { data: order, isLoading: bookingLoading } = useGetSingleBookingQuery(id);


  console.log('order', order);

  const options = [
    { value: "pending", label: "Pending" },
    { value: "process", label: "Process" },
    { value: "deliver", label: "Deliver" },
  ];

  return (
    <Layout pageTitle={words['Booking Detail']}>
      <div className="booking-page min-h-screen flex">
        {bookingLoading ? (
          <div className="wrap wrapWidth flex flex-col items-center ">
            <Loader />
          </div>
        ) : (
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex items-center justify-between gap-3">
              <div className="flex flex-col flex-1 gap-2">
                <div className="flex items-center gap-1">
                  <div className="bg-themeColor h-6 w-6 rounded-md" />
                  <p className="text-[#A7A3A0] font-normal text-sm">
                    {words['Date of order']} : {order.data?.date}
                  </p>
                </div>
                <div className="flex items-center gap-1">
                  <div className="bg-themeColor h-6 w-6 rounded-md" />
                  <p className="text-[#A7A3A0] font-normal text-sm">
                    {words['Payment status']} : {capitalizeFirstLetter(order.data?.payment_status)}
                  </p>
                </div>
              </div>
              {/* <div className="flex flex-[1.4] gap-2 items-end">
                <div className="flex flex-col w-full">
                  <h1 className="text-[#8120F] font-medium text-lg !w-max">
                    Booking Status
                  </h1>
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    className="w-full"
                  />
                </div>
                <button className="button bg-themeColor w-[200px] h-max text-sm !py-2 rounded-md">
                  Update Status
                </button>
              </div> */}
            </div>
            <div className="home-grid w-full grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 my-8">
              <div className="shadow-md-custom bg-white rounded-2xl overflow-hidden">
                <div className="responsive-table1 overflow-x-auto mb-20">
                  <table className="min-w-full ">
                    <thead className="bg-[#F5F7FB]">
                      {/* divide-y divide-gray-200 */}
                      <tr>
                        <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                          {words['name']}
                        </th>
                        <th className="px-2 py-2 text-left w-56 text-sm text-black font-medium">
                          {words['Price']}
                        </th>
                        <th className="px-2 py-2 text-left text-sm text-black font-medium">
                          {words['Quantity']}
                        </th>
                        {/* <th className="px-2 py-2 text-left text-sm text-black font-medium">
                          Sub {words['Total']}
                        </th> */}
                      </tr>
                    </thead>

                    <div className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider" style={{ fontSize: 15 }} >{words['services']}</div>
                    <tbody className="bg-white divide-y divide-gray-200">

                      {order?.data?.services?.map((item, index) => (
                        <tr
                          key={index}
                          className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"
                            }`}
                        >
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {item.name}
                          </td>
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {item.price} {words['OMR']}
                          </td>
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {item.quantity}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <div className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider" style={{ fontSize: 15, }} >{words['products']}</div>
                    <tbody className="bg-white divide-y divide-gray-200">

                      {order?.data?.products?.map((item, index) => (
                        <tr
                          key={index}
                          className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"
                            }`}
                        >
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {item.name}
                          </td>
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {item.price} {words['OMR']}
                          </td>
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {item.quantity}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex items-center justify-between gap-3 bg-[#F5F7FB] px-3 py-2 w-full">
                  <p className="text-black text-sm font-normal">
                    {words['Co %']}: {order?.data?.admin_commision}
                  </p>
                  <p className="text-black text-sm font-normal">
                    <span className="font-semibold">{words['Total']}</span> {order?.data?.total} {words['OMR']}
                  </p>
                </div>
              </div>
              <div className="shadow-md-custom flex flex-col bg-white rounded-2xl p-3">
                <h2 className="text-black text-lg font-medium ">
                  {words['Customer Details']}
                </h2>
                <div className="flex flex-col gap-2 mt-4">
                  <p className="text-[#808080] text-sm font-normal">{words['name']}: {order?.data?.user?.name} </p>
                  <p className="text-[#808080] text-sm font-normal">
                    {words['Contact Number']}: {order?.data?.user?.phone_number}
                  </p>
                  <p className="text-[#808080] text-sm font-normal">{words['email']}: {order?.data?.user?.email}</p>
                  <p className="text-[#808080] text-sm font-normal">{words['Address']}: {order?.data?.user?.address} </p>
                </div>
              </div>
              {/* <div className="shadow-md-custom flex flex-col bg-white rounded-2xl p-3">
                <h2 className="text-black text-lg font-medium ">Service Info</h2>
                <div className="flex flex-col gap-2 mt-4">
                  <p className="text-[#808080] text-sm font-normal">
                    Start Time:
                  </p>
                  <p className="text-[#808080] text-sm font-normal">End Time:</p>
                  <p className="text-[#808080] text-sm font-normal">{words['email']}:</p>
                  <p className="text-[#808080] text-sm font-normal">
                    Service Duration:
                  </p>
                  <p className="text-[#808080] text-sm font-normal">
                    Visiting Charge:
                  </p>
                  <p className="text-[#808080] text-sm font-normal">
                    Tmsir type:
                  </p>
                  <p className="text-[#808080] text-sm font-normal">
                    Tmsir Color:
                  </p>
                  <p className="text-[#808080] text-sm font-normal">Accessory:</p>
                </div>
              </div> */}
              <div className="shadow-md-custom flex flex-col bg-white rounded-2xl p-3">
                <h2 className="text-black text-lg font-medium ">{words['Store Info']}</h2>
                <div className="flex flex-col gap-2 mt-4">
                  <p className="text-[#808080] text-sm font-normal">{words['Company Name']}: {order?.data?.store?.store_name} </p>
                  {/* <p className="text-[#808080] text-sm font-normal">
                    Provider:
                  </p> */}
                  <p className="text-[#808080] text-sm font-normal">{words['email']}: {order?.data?.store?.store_email} </p>
                  {/* <p className="text-[#808080] text-sm font-normal">Phone: {order?.data?.store?.phone_number}</p> */}
                  <p className="text-[#808080] text-sm font-normal">{words['Address']}: {order?.data?.store?.current_location} </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default BookingDetail;
