import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { DotMenuIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import { useGetBookingsQuery, useGetFinanceQuery, useMarkPaymentAsPaidMutation } from "../../store/services/bookingService";
import DataTable from "../../components/DataTable";
import { Dropdown, Menu, Button } from "antd";
import { getDateString } from "../../utils/functions";
import Modal from "../../components/Modal";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Finance = () => {

  const words = useSelector((state) => state.authReducer.words);

  const { data: financeList, isLoading: bookingLoading } =
    useGetFinanceQuery();

  const [submitData, { data: paymentStatusData, isLoading: paymentStatusLoading }] = useMarkPaymentAsPaidMutation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');







  const columns = [
    {
      title: words['id'],
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: words['Order Id'],
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      title: words['store name'],
      dataIndex: 'store_name',
      key: 'store_name',
    },
    {
      title: words['User name'],
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: words['Order amount'],
      dataIndex: 'order_amount',
      key: 'order_amount',
    },
    {
      title: words['Admin commision'],
      dataIndex: 'admin_commission',
      key: 'admin_commission',
    },
    {
      title: words['Store amount'],
      dataIndex: 'store_amount',
      key: 'store_amount',
    },
    {
      title: words['Transaction date'],
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      render: (_, record) => (
        <div>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {getDateString(record.created_at)}
          </div>
        </div>
      ),
    },
    {
      title: words['Is Paid to store'],
      dataIndex: 'is_paid_to_store',
      key: 'is_paid_to_store',
      render: (_, record) => (
        <div>
          {
            record.is_paid_to_store == 1 ? <div
              className="py-2 px-2 rounded-full text-center font-semibold bg-[#0DB214]/40 text-[#0DB214]"
            >
              {words['Paid']}
            </div> :
              <div className="flex items-center justify-center">
                <button
                  className="button rounded-full !py-2 !px-5"
                  onClick={(e) => {
                    setOpenDeleteModal(true);
                    setDeletedId(record.id);
                  }}
                >
                  {words['Mark as paid']}
                </button>
              </div>
          }

        </div>
      ),
    },

  ];

  const doChangeStatus = () => {

    submitData(deletedId);

  }

  useEffect(() => {
    console.log('paymentStatusData', paymentStatusData);
    if (paymentStatusData?.status == 1) {
      toast.success(paymentStatusData.message ?? "Status updated Successfully");

      // setOpenModal(false)
      // navigate("/dashboard/stores");
    }
    if (paymentStatusData?.status == 0) {
      // console.log('deleteResponse.isError', deleteResponse.isError)
      toast.error("Something wrong try again later!");
    }
  }, [paymentStatusData]);

  return (
    <Layout>
      <div className="finance-page min-h-screen flex">
        {bookingLoading || paymentStatusLoading ? (
          <div className="wrap wrapWidth flex flex-col items-center ">
            <Loader />
          </div>
        ) : (
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">

                <div className="flex flex-1 justify-end">

                </div>
              </div>
              {/* <div className="responsive-table overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#F5F7FB]">
                    <tr>
                      <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                        No
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Customer Name
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        ID
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Date
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Amount
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Type
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Payment
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Status
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {financeList?.data?.length ? (
                    <tbody className="bg-white divide-y divide-gray-200">
                      {financeList?.data?.map((item, index) => (
                        <tr
                          key={index}
                          className={`p-2 ${
                            index % 2 === 0 ? "bg-white" : "bg-white"
                          }`}
                        >
                          <td className="px-2 py-2 text-sm text-black font-medium">
                            {index + 1}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            {item.user?.name}
                          </td>
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {item?.order_id}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            {item.date}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            {item.total}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            Booking
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            {item.sub_total}
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            <div
                              className={`py-2 px-2 rounded-full text-center font-semibold capitalize ${
                                item.status === "Completed"
                                  ? "bg-[#0DB214]/40 text-[#0DB214]"
                                  : "bg-red-200 text-red-400"
                              }`}
                            >
                              {item.status}
                            </div>
                          </td>
                          <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                            <div className="flex items-center justify-center cursor-pointer">
                              <DotMenuIcon />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : null}
                </table>
                {!financeList?.data?.length ? (
                  <div className="flex items-center justify-center text-center w-full min-h-[100px] min-w-[300px]">
                    Data not found!
                  </div>
                ) : null}
              </div> */}
              <div style={{ marginTop: -50 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%", }}
                  data={financeList?.data} columns={columns} />
              </div>

            </div>
          </div>
        )}
      </div>

      <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
        <DeleteConfirmationModel
          title=""
          desc="Are you sure you want to mark as paid?"
          openModal={openDeleteModal}
          onSubmit={() => doChangeStatus()}
          setOpenModal={setOpenDeleteModal}
        />
      </Modal>


    </Layout>
  );
};

export default Finance;
