import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DotMenuIcon } from "../../assets/Icons";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { useDeleteServiceMutation } from "../../store/services/storeService";
import AppLoader from "../../components/Loader/AppLoader";
import { toast } from "react-toastify";


const ServiceList = ({ data }) => {


  const words = useSelector((state) => state.authReducer.words);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');

  const [deleteService, deleteResponse] = useDeleteServiceMutation();


  const doDeleteService = async () => {

    try {
      deleteService(deletedId)
    } catch (error) {
    }
  };



  useEffect(() => {
    console.log('deleteResponse', deleteResponse)
    if (deleteResponse.isSuccess) {
      toast.success("Product deleted Successfully");
    }
    if (deleteResponse.isError) {
      // console.log('deleteResponse.isError', deleteResponse.isError)
      toast.error("Something wrong try again later!");
    }
  }, [deleteResponse]);





  const TableRow = ({ item, index }) => {
    const [hide, setHide] = useState(false);
    useEffect(() => {
      document.body.addEventListener("click", () => {
        document.body.style.overflowY = "auto";
        setHide(false);
      });
    }, []);
    return (
      <tr
        key={index}
        className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
      >
        <td className="px-2 py-2 text-sm text-black font-medium">
          {index + 1}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div className="flex items-center gap-2">{item.title}</div>
        </td>
        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
          {item.price} {words['OMR']}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.store_id}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {item.detail}
        </td>

        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div
            className={`py-2 px-2 rounded-full text-center font-semibold capitalize ${item.status === "Active"
              ? "bg-[#0DB214]/40 text-[#0DB214]"
              : "bg-red-200 text-red-400"
              }`}
          >
            {item.status}
          </div>
        </td>

        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32] relative">
          <div className="">
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setHide(!hide);
              }}
            >
              <DotMenuIcon />
            </div>
            <div
              className={`menuList-block flex items-center absolute ${hide ? "show" : ""
                }`}
            >
              <div className="menu-item flex items-center flex-col anim">
                <Link
                  to={`/dashboard/service-detail/${item.ser_id}`}
                  className="slt flex items-center gap-1 justify-between text-white text-sm"
                  onClick={(e) => {
                    setHide(!hide);
                  }}
                >
                  View
                </Link>
                {/* <div
                  className="slt flex items-center gap-1 justify-between text-white text-sm"
                  onClick={(e) => {
                    setHide(!hide);
                  }}
                >
                  Edit
                </div> */}
                <div
                  className="slt flex items-center gap-1 justify-between text-white text-sm"
                  onClick={(e) => {
                    setDeletedId(item.ser_id);
                    setOpenDeleteModal(true);
                    setHide(!hide);
                  }}
                >
                  {words["Delete"]}
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="flex flex-col gap-5 ">
      {
        deleteResponse.isLoading && <AppLoader />
      }
      <div className="responsive-table overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-[#F5F7FB]">
            <tr>
              <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                {words["No"]}
              </th>
              <th className="px-2 py-2 text-left w-56 text-sm text-black font-medium">
                {words["Service Name"]}
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["Price"]}
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["ID Store"]}
              </th>

              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["Detail"]}
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["status"]}
              </th>
              <th className="px-2 py-2 text-left text-sm text-black font-medium">
                {words["action"]}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data?.map((item, index) => (
              <TableRow item={item} index={index} />
            ))}
          </tbody>
        </table>
      </div>

      <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
        <DeleteConfirmationModel
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          onSubmit={() => doDeleteService()}
        />
      </Modal>


    </div>
  );
};

export default ServiceList;
