import { createSlice } from "@reduxjs/toolkit";
import { en } from "../../lang/en";
import { ar } from "../../lang/ar";
const adminToken = localStorage.getItem("admin-token");

const authReducer = createSlice({
  name: "authReducer",
  initialState: {
    adminToken: adminToken ? adminToken : null,
    words: localStorage.getItem('lang') == 'en' ? en : ar,
    lang: localStorage.getItem('lang') ?? 'ar'
  },
  reducers: {
    setAdminToken: (state, action) => {
      state.adminToken = action.payload;
    },
    logout: (state, { payload }) => {
      localStorage.removeItem(payload);
      state.adminToken = null;
      localStorage.clear();
      sessionStorage.clear();
    },
    setWords: (state, action) => {
      state.words = action.payload == 'ar' ? ar : en;
      state.lang = action.payload;
      localStorage.setItem('lang',action.payload)
    }
  },
});
export const { setAdminToken, logout, setWords } = authReducer.actions;
export default authReducer.reducer;
